import { useAppDispatch } from "../../app/hooks";
import styles from "./JournalEntry.module.css";
import { deleteEntry, editEntry } from "./journalSlice";
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { useRef } from "react";

export const JournalEntry = ({ entry }: { entry: { id: string; text: string }}) => {
  const dispatch = useAppDispatch();
  const doDelete = (id: string) => {
    dispatch(deleteEntry(id));
  };

  const doEdit = (e: ContentEditableEvent) => {
    dispatch(editEntry({ id: entry.id, text: e.target.value }));
  };

  const contentEditable = useRef<HTMLDivElement>(null);
  return (
    <div className={styles.entry}>
      <ContentEditable disabled={false} onChange={doEdit} innerRef={contentEditable} html={entry.text} className={styles.entryText} />
      <button onClick={() => doDelete(entry.id)}>✘</button>
    </div>
  );
};
