import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Card } from "../cards/Card";
import { QuickFilter } from "./QuickFilter";

interface Move {
  Name: string;
  Text: string;
  Type: string;
}

interface Category {
  Name: string;
  Moves: Move[];
}

const MoveCard = ({ move }: { move: Move }) => {
  return (
    <div key={move.Name} className="oracle">
      <h4>{move.Name}</h4>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{move.Text}</ReactMarkdown>
    </div>
  );
};

export const MovesScreen = () => {
  const [moveCategories, setMoveCategories] = useState<Category[]>([]);
  const [validMoveNames, setValidMoveNames] = useState<string[]>([]);

  useEffect(() => {
    fetch(
      "ironsworn_moves.json"
    )
      .then((r1) => r1.json())
      .then((d1) => {
        setMoveCategories(d1.Categories);
      });
  }, []);

  return (
    <>
      <div style={{ flex: 1, overflow: 'auto' }}>
        {moveCategories
          .filter((c) =>
            c.Moves.some(
              (x) => !validMoveNames.length || validMoveNames.includes(x.Name)
            )
          )
          .map((c) => (
            <Card
              key={c.Name}
              title={c.Name}
              className={
                "card-type--" +
                c.Name.replace(" Moves", "")
                  .replace(" ", "-")
                  .toLocaleLowerCase()
              }
              defaultExpanded={!!validMoveNames.length}
            >
              {c.Moves.filter(
                (x) => !validMoveNames.length || validMoveNames.includes(x.Name)
              ).map((m) => (
                <MoveCard key={m.Name} move={m} />
              ))}
            </Card>
          ))}
      </div>
      <QuickFilter
        sentences={moveCategories.flatMap((x) => x.Moves).map((x) => x.Name)}
        onFilterChange={setValidMoveNames}
      />
    </>
  );
};
