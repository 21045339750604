export const Checkbox = ({ count }: { count: number }) => {
  const array = [];
  for (let i = 0; i < count; i++) {
    array.push(i);
  }
  return (
    <div className="progressTrackBox">
      {array.map((t, index) => (
        <span
          key={index}
          className={`progressTick progressTick-${index + 1}`}
        ></span>
      ))}
    </div>
  );
};
