import { AskTheOracle } from "./AskTheOracle";
import Oracles from "./oracles";
import { OracleTheme } from "./OracleTheme";
import { PayThePrice } from "./PayThePrice";

const oracles = new Oracles(undefined);

export const OracleScreen = () => {
  const themes = oracles.tables.map((x) => x.theme);
  // Make all themes unique usign javascript
  const uniqueThemes = [...new Set(themes)];
  return (
    <div>
      <AskTheOracle />
      <PayThePrice />
      {uniqueThemes.map((theme) => (
        <OracleTheme key={theme} theme={theme} oracles={oracles} />
      ))}
    </div>
  );
};
