import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { JournalEntry } from "./JournalEntry";
import styles from "./JournalScreen.module.css";
import { create, selectJournal } from "./journalSlice";
import { JournalSettings } from "./JournalSettings";
import OpenAI from 'openai';
import { useStore } from "react-redux";
import { selectProgress, selectSelectedAssets } from "../progress/progressSlice";
import { selectCounter } from "../counters/counterSlice";
import { preparePrompt } from "./prepare-prompt";
import { RootState } from "../../app/store";

function extractSentences(text: string) {
  // Define regular expression to match sentences
  const sentenceRegex = /[A-Z][^.?!]*[.?!]/g;
  
  // Extract all matches
  const sentences = text.match(sentenceRegex);
  
  // Return array of sentences
  return sentences;
}

export const JournalScreen = () => {
  const journal = useAppSelector(selectJournal);
  const dispatch = useAppDispatch();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const addEntry = () => {
    dispatch(create(inputRef.current?.value ?? "Tracker"));
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };
  const [showSettings, setShowSettings] = React.useState(false);

  const [isNarrating, setIsNarrating] = React.useState(false);
  const store = useStore<RootState>();
  const doStoryTell = () => {
    setIsNarrating(true);

    const client = new OpenAI({
      apiKey: journal.apiKey, // This is the default and can be omitted
      dangerouslyAllowBrowser: true
    });

    const state = store.getState();

    const progress = selectProgress(state);
    const counter = selectCounter(state);
    const selectedAssets = selectSelectedAssets(state);

    const promptToUse = journal.entries.length ? journal.nextJournalEntryPrompt : journal.introduceNewQuestPrompt;
    const prompt = preparePrompt(journal.prompt + " " + promptToUse, journal, counter, progress, selectedAssets);
    if (!prompt) {
      setIsNarrating(false);
      return;
    }
    
    client.chat.completions.create({
      messages: [{ role: 'user', content: prompt }],
      model: 'gpt-4o',
    }).then((response) => {
      if (!response.choices || !response.choices.length || !response.choices[0].message || !response.choices[0].message.content) {
        setIsNarrating(false);
        return;
      }
      const sentences = extractSentences(response.choices[0].message.content);
      if (sentences) {
        for (const sentence of sentences) {
          dispatch(create(sentence));
        }
      }
      setIsNarrating(false);
    });
  };
  
  if (showSettings) {
      return <JournalSettings journal={journal} onClose={
        () => setShowSettings(false)
      }/>;

  }
  return (
    <>
      <div className={styles.journal}>
        <h1>Journal</h1>
        {journal.entries.map((e) => (
          <JournalEntry key={e.id} entry={e} />
        ))}
        <div className={styles.sideBySide}>
          <button disabled={isNarrating} className={styles.storyTellingButton} onClick={doStoryTell}>
            {isNarrating ? 'Finding my words...' : 'Narrate'}
          </button>
          <button onClick={() => setShowSettings(true)}>⚙️</button>
        </div>
      </div>
      <div className={styles.field}>
        <input
          placeholder="Journal entry..."
          ref={inputRef}
          className={styles.input}
          type="text"
        />
        <button className={styles.button} onClick={addEntry}>
          Add
        </button>
      </div>
    </>
  );
};
