import React from "react";
import { Card } from "../cards/Card";
import { Oracle } from "./Oracle";
import Oracles from "./oracles";

export const OracleTheme = ({
  theme,
  oracles,
}: {
  oracles: Oracles;
  theme: string;
}) => {
  return (
    <Card key={theme} title={theme}>
      {oracles.tables
        .filter((x) => x.theme === theme)
        .map((x) => (
          <Oracle key={x.title} oracles={oracles} name={x.title} />
        ))}
    </Card>
  );
};
