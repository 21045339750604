import React from "react";
import DiceRoller from "./dice-roller";
import styles from './AskTheOracle.module.css';
import { Card } from "../cards/Card";
const diceRoller = new DiceRoller();

export const AskTheOracle = () => {
  const [outputValue, setOutputValue] = React.useState("");
  const handleAskOracleRoll = (min: number) => {
    let rn = diceRoller.roll([100], true, false)[0].value;
    let ans = rn >= min ? `Yes` : `No`;

    setOutputValue(ans);
  };
  return (
    <Card title='Ask the Oracle'>
          <div className={styles.buttons}>
            <button onClick={() => handleAskOracleRoll(91)} type="button">
              Small Chance
            </button>
            <button onClick={() => handleAskOracleRoll(76)} type="button">
              Unlikely
            </button>
            <button onClick={() => handleAskOracleRoll(51)} type="button">
              50/50
            </button>
            <button onClick={() => handleAskOracleRoll(26)} type="button">
              Likely
            </button>
            <button onClick={() => handleAskOracleRoll(11)} type="button">
              Almost Certain
            </button>
          </div>
          <div className={styles.result}>{outputValue}</div>
    </Card>
  );
};
