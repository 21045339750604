import React from "react";
import "./App.css";
import { OracleScreen } from "./features/oracles/OracleScreen";
import { MovesScreen } from "./features/moves/MovesScreen";
import { ProgressScreen } from "./features/progress/ProgressScreen";
import { DiceTray } from "./features/dice/DiceTray";
import { JournalScreen } from "./features/journal/JournalScreen";
import { useSelector } from "react-redux";
import { selectSelectedScreen } from "./features/ui/uiSlice";
import { AppScreen } from "./app/constants";
import { AssetsScreen } from "./features/assets/AssetsScreen";
import { FooterNav } from "./features/navigation/FooterNav";

function App({ children }: { children?: React.ReactNode }) {
  const selectedScreen = useSelector(selectSelectedScreen);
  
  return (
    <div className="app">
      <div className="app__content">
        {selectedScreen === AppScreen.Journal && <JournalScreen />}
        {selectedScreen === AppScreen.Progress && <ProgressScreen />}
        {selectedScreen === AppScreen.Assets && <AssetsScreen />}
        {selectedScreen === AppScreen.Oracles && <OracleScreen />}
        {selectedScreen === AppScreen.Moves && <MovesScreen />}
      </div>
      {(selectedScreen === AppScreen.Moves || selectedScreen === AppScreen.Progress) && (
        <DiceTray />
      )}
      <FooterNav selectedScreen={selectedScreen} />
    </div>
  );
}

export default App;
