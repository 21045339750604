import clsx from "clsx";
import { useCallback } from "react";
import { AppScreen } from "../../app/constants";
import { useAppDispatch } from "../../app/hooks";
import { selectScreen } from "../ui/uiSlice";

const iconSize = 48;

export const FooterNav = ({ selectedScreen }: { selectedScreen: AppScreen }) => {
    const d = useAppDispatch();
  const setScreen = useCallback((screen: AppScreen) => {
    d(selectScreen(screen));
  }, [d]);

  const onProgressButtonClick = () => {
    setScreen(AppScreen.Progress);
  };

  const onOracleButtonClick = () => {
    setScreen(AppScreen.Oracles);
  };

  const onAssetsButtonClick = () => {
    setScreen(AppScreen.Assets);
  };

  const onReferenceButtonClick = () => {
    setScreen(AppScreen.Moves);
  };

  const onJournalButtonClick = () => {
    setScreen(AppScreen.Journal);
  };
  return <footer>
        <button
          onClick={onJournalButtonClick}
          className={clsx("footer__button", { selected: selectedScreen === AppScreen.Journal })}
        >
          <img src="journal.svg" height={iconSize} />
          Journal
        </button>
        <button
          onClick={onProgressButtonClick}
          className={clsx("footer__button", { selected: selectedScreen === AppScreen.Progress })}
        >
          <img src="journey.svg" height={iconSize} />
          Sheet
        </button>
        <button
          onClick={onAssetsButtonClick}
          className={clsx("footer__button", { selected: selectedScreen === AppScreen.Assets })}
        >
          <img src="cards.svg" height={iconSize} />
          Assets
        </button>
        <button
          onClick={onOracleButtonClick}
          className={clsx("footer__button", { selected: selectedScreen === AppScreen.Oracles })}
        >
          <img src="oracle.svg" height={iconSize} />
          Oracle
        </button>
        <button
          onClick={onReferenceButtonClick}
          className={clsx("footer__button", { selected: selectedScreen === AppScreen.Moves })}
        >
          <img src="book.svg" height={iconSize} />
          Moves
        </button>
      </footer>;
}