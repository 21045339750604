import React, { useState } from 'react';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  decrement,
  increment,
  selectCounter,
} from './counterSlice';
import styles from './Counter.module.css';

export function Counter({ name } : { name: string }) {
  const counter = useAppSelector(selectCounter);
  const count = counter.counters[name] ?? 0;
  const dispatch = useAppDispatch();

  return (
      <div className={styles.counter}>
        <div className={styles.name}>{name}</div>
        <div>
          <button
            className={styles.button}
            aria-label="Decrement value"
            onClick={() => dispatch(decrement(name))}
          >
            -
          </button>
          <span className={styles.value}>{count}</span>
          <button
            className={styles.button}
            aria-label="Increment value"
            onClick={() => dispatch(increment(name))}
          >
            +
          </button>
        </div>
      </div>
  );
}
