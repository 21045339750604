import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppScreen } from "../../app/constants";
import { RootState } from "../../app/store";

export interface UIState {
  selectedScreen: AppScreen;
  selectedAsset?: string;
}

const initialState: UIState = {
  selectedScreen: AppScreen.Progress,
  selectedAsset: undefined,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    selectScreen: (state, action: PayloadAction<AppScreen>) => {
      state.selectedScreen = action.payload;
      state.selectedAsset = undefined;
    },
    selectAsset: (state, action: PayloadAction<string | undefined>) => {
        state.selectedScreen = AppScreen.Assets;
        state.selectedAsset = action.payload;
    },
  },
});

export const { selectScreen, selectAsset } = uiSlice.actions;

export const selectSelectedScreen = (state: RootState) => state.ui.selectedScreen;
export const selectSelectedAsset = (state: RootState) => state.ui.selectedAsset;

export default uiSlice.reducer;
