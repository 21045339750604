import { useEffect, useState } from "react";
import { Card } from "../cards/Card";
import { Asset } from "./types";
import coreAssetIcons from "./assetIcons";
import { AssetCard } from "./AssetCard";
import { useSelector } from "react-redux";
import { selectSelectedAsset } from "../ui/uiSlice";
import styles from "./AssetsScreen.module.css";
import { selectSelectedAssets } from "../progress/progressSlice";

const assetCategories = ['Companion', 'Path', 'Combat Talent', 'Ritual'];

export const AssetsScreen = () => {
  const [assets, setAssets] = useState<Asset[]>([]);
  const selectedAssets = useSelector(selectSelectedAssets);

  useEffect(() => {
    fetch(
      "ironsworn_assets.json"
    )
      .then((response) => response.json())
      .then((data) => {
        const newAssets: Asset[] = [];
        for (let i = 0; i < data["Assets"].length; i++) {
          const asset = data["Assets"][i];
          asset.id = `core-${asset.Name.toLowerCase().replace(" ", "-")}`;
          asset.core = true;
          asset.front = true;
          asset.icon = "crystal-ball";
          asset.augmented = false;
          let coreIcon = coreAssetIcons.find((c) => c.id == asset.id);
          if (coreIcon) asset.icon = coreIcon.icon;
          asset.Type = asset["Asset Type"] ? asset["Asset Type"] : [];
          asset.InputFields = asset["Input Fields"]
            ? asset["Input Fields"]
            : [];
          asset.TrackMax = 0;
          if (asset.Health) asset.TrackMax = asset.Health;

          if (asset["Asset Track"]) {
            asset.TrackMax = asset["Asset Track"].Max;
            delete asset["Asset Track"];
          }
          asset.TrackValue = asset.Type === "Companion" ? asset.TrackMax : 0;

          if (asset.MultiFieldAssetTrack) {
            asset.TrackLabels = asset.MultiFieldAssetTrack.Fields.map(
              (f: any) => f.ActiveText
            );
            delete asset.MultiFieldAssetTrack;
          } else {
            asset.TrackLabels = [];
          }
          delete asset["Asset Type"];
          delete asset["Input Fields"];
          delete asset.Health;

          if (asset.InputFields !== undefined) {
            asset.InputFields = asset.InputFields.map((f: string) => {
              return { name: f, value: "" };
            });
          }

          asset.Abilities = asset.Abilities.map((a: any) => {
            a.Enabled = a.Enabled !== undefined ? a.Enabled : false;
            return a;
          });

          if (asset.id === "core-kindred") {
            asset.InputFields.push({
              name: asset.Abilities[0]["Input Fields"][0],
              value: "",
            });
          }

          newAssets.push(asset);
        }
        setAssets(newAssets);
      });
  }, []);

  const assetToFocusOn = useSelector(selectSelectedAsset);
  const scrollToAsset = assets.find((a) => a.id === assetToFocusOn);

  const ownedAssets = selectedAssets.map(x => assets.find(a => a.id === x.id)).filter(x => x !== undefined) as Asset[];

  return (
    <div className={styles.container}>
      <div className={styles.hand}>
        {ownedAssets.map(a => <AssetCard key={a.id} asset={a} />)}
      </div>
      {assetCategories.map((c) => (
        <Card key={c} title={c + ' Assets'} className='card-type--asset' defaultExpanded={scrollToAsset?.Type === c}>
          <div className={styles.hand}>
          {assets
            .filter((a) => a.Type === c)
            .map((a) => (
              <AssetCard key={a.id} asset={a} shouldScrollIntoView={scrollToAsset?.id === a.id}/>
            ))}
            </div>
        </Card>
      ))}
    </div>
  );
};
