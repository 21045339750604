import React, { useEffect } from "react";
import { Card } from "../cards/Card";
import DiceRoller from "./dice-roller";

const payThePriceTable = [
    { min: 99, text: 'Roll twice more on this table. Both results occur. If they are the same result, make it worse.' },
    { min: 95, text: 'A friend, companion, or ally is put in harm’s way (or you are, if alone).' },
    { min: 91, text: 'It forces you to act against your best intentions.' },
    { min: 86, text: 'It wastes resources.' },
    { min: 78, text: 'A surprising development complicates your quest.' },
    { min: 69, text: 'It is stressful.' },
    { min: 60, text: 'It is harmful.' },
    { min: 51, text: 'It causes a delay or puts you at a disadvantage.' },
    { min: 42, text: 'A new danger or foe is revealed.' },
    { min: 33, text: 'The current situation worsens.' },
    { min: 24, text: 'Something of value is lost or destroyed.' },
    { min: 17, text: 'Your action has an unintended effect.' },
    { min: 10, text: 'You are separated from something or someone.' },
    { min: 6, text: 'A person or community you care about is exposed to danger.' },
    { min: 3, text: 'A person or community you trusted loses faith in you, or acts against you.' },
    { min: 1, text: 'Roll again and apply that result but make it worse. If you roll this result yet again, think of something dreadful that changes the course of your quest (Ask the Oracle if unsure) and make it happen. ' },
];

const diceRoller = new DiceRoller();
export const PayThePrice = () => {
    const [outputValue, setOutputValue] = React.useState('');
    const handleAskOracleRoll = () => {
        const result = diceRoller.roll([100], true, false)[0].value;
        for (const outcome of payThePriceTable) {
            if (result >= outcome.min) {
                setOutputValue(outcome.text);
                return;
            }
        } 
    };
    useEffect(() => {
        handleAskOracleRoll();
    }, [outputValue]);
  

    return <Card title='Pay the price'>
        <div className='oracle' onClick={handleAskOracleRoll}>
            <span className='oracle__name'>Result</span>
            <span className='oracle__value'>{outputValue}</span>
        </div>
    </Card>
};