import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface CounterState {
  counters: { [counterName: string]: number; }
}

const initialState: CounterState = {
  counters: {}
};

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    increment: (state, action: PayloadAction<string>) => {
      state.counters[action.payload] = (state.counters[action.payload] ?? 0) + 1;
    },
    decrement: (state, action: PayloadAction<string>) => {
      state.counters[action.payload] = (state.counters[action.payload] ?? 0) - 1;
    },
  },
});

export const { increment, decrement } = counterSlice.actions;

export const selectCounter = (state: RootState) => state.counter;

export default counterSlice.reducer;
