import React, { useCallback, useEffect } from "react";
import { D10 } from "./D10";
import { D6 } from "./D6";
import "./DiceTray.css";

export const DiceTray = () => {
  // Set up a state variable that holds a random value from 1 to 6
  const [actionDieValue, setActionDieValue] = React.useState(
    Math.floor(Math.random() * 6) + 1
  );
  // Set up a state variable that holds a random value from 0 to 9
  const [challengeDie1, setChallengeDie1] = React.useState(
    Math.floor(Math.random() * 10)
  );
  const [challengeDie2, setChallengeDie2] = React.useState(
    Math.floor(Math.random() * 10)
  );

  const reRoll = useCallback(() => {
    setActionDieValue(Math.floor(Math.random() * 6) + 1);
    setChallengeDie1(Math.floor(Math.random() * 10));
    setChallengeDie2(Math.floor(Math.random() * 10));
  }, []);

  return (
    <div className="dice-tray" onClick={reRoll}>
      <D6 value={actionDieValue} />
      <D10 value={challengeDie1} size={64} />
      <D10 value={challengeDie2} size={64} />
    </div>
  );
};
