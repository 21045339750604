import { ProgressState } from "./journalSlice";

function prepareStats(counter: { counters: { [key: string]: number; }; }) {
    return `Edge: ${counter.counters["edge"] || 0}, Heart: ${counter.counters["heart"] || 0}, Iron: ${counter.counters["iron"] || 0}, Shadow: ${counter.counters["shadow"] || 0}, Wits: ${counter.counters["wits"] || 0}, Momentum: ${counter.counters["momentum"] || 0}, Health: ${counter.counters["health"] || 0}, Spirit: ${counter.counters["spirit"] || 0}, Supply: ${counter.counters["supply"] || 0}`;
}

function prepareTracks(progress: { trackers: { id: string; name: string; progress: number; }[]; }) {
    if (!progress.trackers.length) {
        return "No trackers yet";
    }
    return progress.trackers.map((t) => `${t.name} (${t.progress}/40)`).join(", ");
}

function prepareAssets(selectedAssets: { id: string; trackValue?: number; abilities: string[]; inputFields: { [key: number]: string; }; }[]) {
    if (!selectedAssets.length) {
        return "No assets selected";
    }
    return selectedAssets.map((a) => `${a.id}`).join(", ");
}

export const preparePrompt = (promptTemplate: string | undefined, journal: ProgressState, counter: { counters: { [key: string]: number; }; }, progress: { trackers: { id: string; name: string; progress: number; }[]; }, selectedAssets: { id: string; trackValue?: number; abilities: string[]; inputFields: { [key: number]: string; }; }[]) => {
    if (!promptTemplate) {
        return;
    }

    let prompt = promptTemplate.replaceAll("{stats}", prepareStats(counter));
    prompt = prompt.replaceAll("{tracks}", prepareTracks(progress));
    prompt = prompt.replaceAll("{assets}", prepareAssets(selectedAssets));
    prompt = prompt.replaceAll("{journal}", journal.entries.map((e, index) => `${index + 1}: ${e.text}`).join("\n\n"));
    return prompt;
}