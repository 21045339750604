import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import styles from "./Card.module.css";

export const Card = ({
    title,
    className,
    children,
    defaultExpanded = false,
  }: {
    title: string;
    className?: string;
    children?: React.ReactNode;
    defaultExpanded?: boolean;
  }) => {
    const [expanded, setExpanded] = useState(defaultExpanded);
    const toggleExpanded = useCallback(() => setExpanded(!expanded), [expanded]);
    useEffect(() => {
      setExpanded(defaultExpanded);
    }, [defaultExpanded]);
    return (
      <div className={clsx(styles.card, className)}>
        <h3 className={styles.title} onClick={toggleExpanded}>{title}</h3>
        {expanded && <div className={styles.content}>{children}</div>}
      </div>
    );
  };