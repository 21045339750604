import React, { useCallback } from "react";
import styles from "./JournalScreen.module.css";
import { ProgressState, saveSettings } from "./journalSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectProgress, selectSelectedAssets } from "../progress/progressSlice";
import { selectCounter } from "../counters/counterSlice";
import { useSelector } from "react-redux";
import { preparePrompt } from "./prepare-prompt";

const PromptField = ({ inputRef, rows, label, value, setValue }: { rows: number; inputRef: React.RefObject<HTMLTextAreaElement>; label: string; value: string, setValue: (newValue: string) => void }) => {
    
    return <div className={styles.formInput}>
        <label>{label}</label>
        <textarea ref={inputRef} className={styles.textArea} rows={rows} onChange={(e) => {
            setValue(e.target.value);
        }} value={value} placeholder="prompt..."></textarea>
    </div>;
}

export const JournalSettings = ({ journal, onClose }: { journal: ProgressState; onClose: () => void }) => {
    const promptRef = React.useRef<HTMLTextAreaElement>(null);
    const [prompt, setPrompt] = React.useState(journal.prompt);

    const nextJournalEntryPromptRef = React.useRef<HTMLTextAreaElement>(null);
    const [nextJournalEntryPrompt, setNextJournalEntryPrompt] = React.useState(journal.nextJournalEntryPrompt);

    const introduceNewQuestPromptRef = React.useRef<HTMLTextAreaElement>(null);
    const [introduceNewQuestPrompt, setIntroduceNewQuestPrompt] = React.useState(journal.introduceNewQuestPrompt);

    const apiKeyRef = React.useRef<HTMLInputElement>(null);
    const [apiKey, setApiKey] = React.useState(journal.apiKey);

    const dispatch = useAppDispatch();

    const doSaveSettings = useCallback(() => {
        dispatch(saveSettings({ 
            prompt: promptRef.current?.value ?? "", 
            apiKey: apiKeyRef.current?.value ?? "", 
            nextJournalEntryPrompt: nextJournalEntryPromptRef.current?.value ?? "",
            introduceNewQuestPrompt: introduceNewQuestPromptRef.current?.value ?? ""
        }));
    }, [dispatch]);


    const progress = useAppSelector(selectProgress);
    const counter = useAppSelector(selectCounter);
    const selectedAssets = useSelector(selectSelectedAssets);

    const createPrompt = () => {
        let promptTemplate = promptRef.current?.value;
        const prompt = preparePrompt(promptTemplate, journal, counter, progress, selectedAssets);
        console.log(prompt);
    }

    return <div className={styles.journal}>
        <button className={styles.settingsButton} onClick={onClose}>✘</button>
        <h1>Journal settings</h1>
        <div className={styles.formInput}>
            <label>OpenAI API key</label>
            <input ref={apiKeyRef} className={styles.password} type="password" onChange={(e) => {
                setApiKey(e.target.value);
            }} value={apiKey} />
        </div>
        <PromptField inputRef={promptRef} label="Common prompt" value={prompt} setValue={setPrompt} rows={20} />
        <PromptField rows={5} inputRef={nextJournalEntryPromptRef} label="Prompt for next journal entry" value={nextJournalEntryPrompt} setValue={setNextJournalEntryPrompt} />
        <PromptField rows={5} inputRef={introduceNewQuestPromptRef} label="Prompt to kickstart an adventure" value={introduceNewQuestPrompt} setValue={setIntroduceNewQuestPrompt} />
        <button onClick={doSaveSettings}>Save</button>
        <button onClick={onClose}>Close</button>
        <button onClick={createPrompt}>Test</button>
    </div>;
}