import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { generateId } from "../../app/utils";

export interface ProgressState {
  trackers: { id: string; name: string; progress: number }[];
  selectedAssets: { id: string; trackValue?: number; abilities: string[]; inputFields: { [key: number]: string }}[];
}

const initialState: ProgressState = {
  trackers: [],
  selectedAssets: [],
};

type ToggleAbilityPayload = {
  assetId: string;
  abilityId: string;
};

export const progressSlice = createSlice({
  name: "progress",
  initialState,
  reducers: {
    updateStatTrack: (state, action: PayloadAction<{ assetId: string; trackId: string; value: number }>) => {
      const asset = state.selectedAssets.find((a) => a.id === action.payload.assetId);
      if (!asset) {
        return;
      }
      asset.trackValue = action.payload.value;
    },
    toggleSelectAsset: (state, action: PayloadAction<string>) => {
      const asset = state.selectedAssets.find((a) => a.id === action.payload);
      if (asset) {
        state.selectedAssets = state.selectedAssets.filter((a) => a.id !== action.payload);
      } else {
        state.selectedAssets.push({ id: action.payload, abilities: [], inputFields: {} });
      }
    },
    updateInputField: (state, action: PayloadAction<{ assetId: string; fieldIndex: number; value: string }>) => {
      const asset = state.selectedAssets.find((a) => a.id === action.payload.assetId);
      if (!asset) {
        return;
      }
      if (!asset.inputFields) {
        asset.inputFields = {};
      }
      asset.inputFields[action.payload.fieldIndex] = action.payload.value;
    },

    toggleAbility: (state, action: PayloadAction<ToggleAbilityPayload>) => {
      const asset = state.selectedAssets.find((a) => a.id === action.payload.assetId);
      if (!asset) {
        return;
      }
      if (!asset.abilities) {
        asset.abilities = [];
      }
      const ability = asset.abilities.find((a) => a === action.payload.abilityId);
      if (ability) {
        asset.abilities = asset.abilities.filter((a) => a !== action.payload.abilityId);
      } else {
        asset.abilities.push(action.payload.abilityId);
      }
    },
    create: (state, action: PayloadAction<string>) => {
      state.trackers.push({ id: generateId(), name: action.payload, progress: 0 });
    },
    deleteTracker: (state, action: PayloadAction<string>) => {
      state.trackers = state.trackers.filter((t) => t.id !== action.payload);
    },
    increment: (state, action: PayloadAction<string>) => {
      const tracker = state.trackers.find((t) => t.id === action.payload);
      if (tracker) {
        tracker.progress++;
      }
    },
    decrement: (state, action: PayloadAction<string>) => {
      const tracker = state.trackers.find((t) => t.id === action.payload);
      if (tracker) {
        tracker.progress--;
      }
    },
  },
});

export const { updateStatTrack, increment, decrement, create, deleteTracker, toggleSelectAsset, toggleAbility, updateInputField } = progressSlice.actions;

export const selectProgress = (state: RootState) => state.progress;
export const selectSelectedAssets = (state: RootState) => state.progress.selectedAssets;

export default progressSlice.reducer;
