import React, { useMemo } from "react";
import { Checkbox } from "./Checkbox";
import "./ProgressTrack.css";

export const ProgressTrack = ({
  title,
  progress,
  length,
  onIncrement,
  onDecrement,
  onDelete
}: {
  title: string;
  progress: number;
  length: number;
  onIncrement: () => void;
  onDecrement: () => void;
  onDelete: () => void;
}) => {
  const checks = useMemo(() => {
    let checks = [];
    for (let i = 0; i < length; i++) {
      checks.push(0);
    }
    // let checks = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (let i = 0; i < progress; i++) {
      let idx = i === 0 ? 0 : Math.floor(i / 4);
      checks[idx] = (i % 4) + 1;
    }
    return checks;
  }, [length, progress]);
  return (
    <ul className="progressTrack mt-2 text-center">
      {title && <div className="progressTrackTitle">{title} <button onClick={onDelete}>✘</button></div>}
      <div className="progressTrackBar">
        <li className="progressBtn">
            <button
            className="btn btn-secondary progressTrackBtn"
            onClick={onDecrement}
            >
            -
            </button>
        </li>
        {checks.map((c, i) => (
            <li key={`progress_track_checks_${i}`} className="progress-check-li">
            <Checkbox count={c} />
            </li>
        ))}
        <li className="progressBtn">
            <button className="btn btn-dark progressTrackBtn" onClick={onIncrement}>
            +
            </button>
        </li>
      </div>
    </ul>
  );
};
