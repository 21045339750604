import Oracles from "./oracles";
import './Oracle.css';
import { useState } from "react";

export const Oracle = ({ oracles, name }: { oracles: Oracles; name: string; }) => {
    const [value, setValue] = useState(oracles.getRandomPromptFromOracleTable(name));
    // set a new value when the oracle is clicked
    const onClick = () => setValue(oracles.getRandomPromptFromOracleTable(name));

    return <div className='oracle' onClick={onClick}>
        <span className='oracle__name'>{name}</span>
        <span className='oracle__value'>{value}</span>
    </div>
}