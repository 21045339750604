const coreAssetIcons = [
    { id: "core-cave-lion", icon: "saber-toothed-cat-head" },
    { id: "core-giant-spider", icon: "hanging-spider" },
    { id: "core-hawk", icon: "egyptian-bird" },
    { id: "core-horse", icon: "horse-head" },
    { id: "core-hound", icon: "sniffing-dog" },
    { id: "core-kindred", icon: "character" },
    { id: "core-mammoth", icon: "mammoth" },
    { id: "core-owl", icon: "owl" },
    { id: "core-raven", icon: "raven" },
    { id: "core-young-wyvern", icon: "wyvern" },
    { id: "core-alchemist", icon: "fizzing-flask" },
    { id: "core-animal-kin", icon: "octogonal-eye" },
    { id: "core-banner-sworn", icon: "flying-flag" },
    { id: "core-battle-scarred", icon: "triple-scratches" },
    { id: "core-blade-bound", icon: "rune-sword" },
    { id: "core-bonded", icon: "heart-shield" },
    { id: "core-dancer", icon: "bad-gnome" },
    { id: "core-devotant", icon: "sundial" },
    { id: "core-empowered", icon: "crenel-crown" },
    { id: "core-fortune-hunter", icon: "receive-money" },
    { id: "core-herbalist", icon: "three-leaves" },
    { id: "core-honorbound", icon: "cut-palm" },
    { id: "core-improviser", icon: "slalom" },
    { id: "core-infiltrator", icon: "annexation" },
    { id: "core-loyalist", icon: "shaking-hands" },
    { id: "core-masked", icon: "mecha-mask" },
    { id: "core-oathbreaker", icon: "shattered-sword" },
    { id: "core-outcast", icon: "orb-direction" },
    { id: "core-pretender", icon: "cowled" },
    { id: "core-revenant", icon: "heartburn" },
    { id: "core-rider", icon: "cavalry" },
    { id: "core-ritualist", icon: "divided-spiral" },
    { id: "core-shadow-kin", icon: "shadow-follower" },
    { id: "core-sighted", icon: "third-eye" },
    { id: "core-slayer", icon: "rock-golem" },
    { id: "core-spirit-bound", icon: "haunting" },
    { id: "core-storyweaver", icon: "book-aura" },
    { id: "core-trickster", icon: "cultist" },
    { id: "core-veteran", icon: "mailed-fist" },
    { id: "core-waterborn", icon: "big-wave" },
    { id: "core-wayfinder", icon: "compass" },
    { id: "core-weaponmaster", icon: "all-for-one" },
    { id: "core-wildblood", icon: "burning-forest" },
    { id: "core-wright", icon: "3d-hammer" },
    { id: "core-archer", icon: "high-shot" },
    { id: "core-berserker", icon: "flat-paw-print" },
    { id: "core-brawler", icon: "fist-2" },
    { id: "core-cutthroat", icon: "dripping-knife" },
    { id: "core-duelist", icon: "sword-clash" },
    { id: "core-fletcher", icon: "arrow-flights" },
    { id: "core-ironclad", icon: "viking-helmet" },
    { id: "core-long-arm", icon: "wizard-staff" },
    { id: "core-shield-bearer", icon: "viking-shield" },
    { id: "core-skirmisher", icon: "spear-feather" },
    { id: "core-slinger", icon: "sling" },
    { id: "core-sunderer", icon: "battered-axe" },
    { id: "core-swordmaster", icon: "winged-sword" },
    { id: "core-thunder-bringer", icon: "hammer-drop" },
    { id: "core-augur", icon: "crow-dive" },
    { id: "core-awakening", icon: "raise-zombie" },
    { id: "core-bind", icon: "wolf-head" },
    { id: "core-communion", icon: "candle-light" },
    { id: "core-divination", icon: "rune-stone" },
    { id: "core-invoke", icon: "embrassed-energy" },
    { id: "core-keen", icon: "dripping-blade" },
    { id: "core-leech", icon: "stigmata" },
    { id: "core-lightbearer", icon: "explosion-rays" },
    { id: "core-scry", icon: "fire-dash" },
    { id: "core-shadow-walk", icon: "abstract-116" },
    { id: "core-sway", icon: "waves" },
    { id: "core-talisman", icon: "tribal-pendant" },
    { id: "core-tether", icon: "chain-lightning" },
    { id: "core-totem", icon: "totem" },
    { id: "core-visage", icon: "double-face-mask" },
    { id: "core-ward", icon: "eclipse-flare" },
    { id: "core-commander", icon: "overlord-helm" },
    { id: "core-fated", icon: "crystal-ball" },
    { id: "core-lorekeeper", icon: "enlightenment" },
  ];
  
  export default coreAssetIcons;