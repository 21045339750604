import { useMemo, useState } from "react";
import styles from './QuickFilter.module.css';

// for each word in the list of sentences, build an index of the first letters of each word
function buildIndex(sentences: string[]) {
    const index: { [key: string]: string[] } = {};
    sentences.forEach((sentence) => {
        const words = sentence.split(' ');
        // concatenate all the first letters of each word in the sentence
        const firstLetters = words.map((word) => word[0]).join('').toLocaleLowerCase();
        if (index[firstLetters]) {
            index[firstLetters].push(sentence);
        } else {
            index[firstLetters] = [sentence];
        }
    });
    return index;
}

export const QuickFilter = ({ sentences, onFilterChange }: { sentences: string[], onFilterChange: (sentences: string[]) => void } ) => {
    const [filter, setFilter] = useState<string>('');

    const letterIndex = useMemo(() => buildIndex(sentences), [sentences]);

    const addToFilter = (letter: string) => {
        setFilter(filter + letter);
        const validSentences = Object.keys(letterIndex).filter(x => x.startsWith(filter + letter)).flatMap(x => letterIndex[x]);
        onFilterChange(validSentences);

    };

    const clearFilter = () => {
        setFilter('');
        onFilterChange([]);
    };


    // find first letter of each word in the filter
    const availableLetters = useMemo(() => {
        const validKeys = Object.keys(letterIndex).filter(x => x.startsWith(filter));
        const firstLetters = validKeys.map(x => x[filter.length]).filter(x => x);
        return [...new Set(firstLetters)].sort();
    }, [filter, letterIndex]);

    return <div className={styles.buttons}>{availableLetters.map(x => <button onClick={() => addToFilter(x)} key={x}>{x}</button>)}{!!filter.length && <button className={styles.delete} onClick={clearFilter}>✘ {filter}</button>}</div>;
}