import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { generateId } from "../../app/utils";


export interface ProgressState {
  prompt: string;
  nextJournalEntryPrompt: string;
  introduceNewQuestPrompt: string;
  apiKey: string;
  entries: { id: string; text: string }[];
}

const initialState: ProgressState = {
  prompt: `You are a great fantasy writer in the style of Tolkien, and you are assisting me play a roleplaying game of Ironsworn.

# The game world
The game takes place in the world of the Witcher story.

# My character

{stats}
Trackers: {tracks}
Assets: {assets}

# Journal

{journal}`,
  apiKey: "",
  nextJournalEntryPrompt: "Respond with a single sentence, a description of the next scene that my character must face.",
  introduceNewQuestPrompt: "Introduce a new quest to my character from his perspective.",
  entries: [],
};

export const journalSlice = createSlice({
  name: "journal",
  initialState,
  reducers: {
    saveSettings: (state, action: PayloadAction<{ prompt: string, apiKey: string; introduceNewQuestPrompt: string; nextJournalEntryPrompt: string; }>) => {
      state.introduceNewQuestPrompt = action.payload.introduceNewQuestPrompt;
      state.nextJournalEntryPrompt = action.payload.nextJournalEntryPrompt;
      state.prompt = action.payload.prompt;
      state.apiKey = action.payload.apiKey;
    },
    create: (state, action: PayloadAction<string>) => {
      state.entries.push({ id: generateId(), text: action.payload });
    },
    deleteEntry: (state, action: PayloadAction<string>) => {
      state.entries = state.entries.filter((t) => t.id !== action.payload);
    },
    editEntry: (state, action: PayloadAction<{ id: string; text: string }>) => {
      const { id, text } = action.payload;
      const entry = state.entries.find((t) => t.id === id);
      if (entry) {
        entry.text = text;
      }
    }
  },
});

export const { create, deleteEntry, saveSettings, editEntry } = journalSlice.actions;

export const selectJournal = (state: RootState) => state.journal;

export default journalSlice.reducer;
