import React from 'react';
import './D10.css';

export const D10 = ({ value, size = 64 }: { value: number; size?: number }) => {
    return (
        <span className='d10'>
            <span className='d10__value'>{value}</span>
            <img src='d10.svg' height={size} />
        </span>
    );
};