import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Counter } from "../counters/Counter";
import { selectProgress, create, increment, decrement, deleteTracker } from "./progressSlice";
import { ProgressTrack } from "./ProgressTrack";

export const ProgressScreen = () => {
  const progress = useAppSelector(selectProgress);
  const dispatch = useAppDispatch();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const addTracker = () => {
    dispatch(create(inputRef.current?.value ?? 'Tracker'));
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };
  
  return (
    <div>
      <div className='centered-text'>
        <Counter name="edge" />
        <Counter name="heart" />
        <Counter name="iron" />
        <Counter name="shadow" />
        <Counter name="wits" />
      </div>
      <div className='centered-text'>
        <Counter name="momentum" />
        <Counter name="health" />
        <Counter name="spirit" />
        <Counter name="supply" />
      </div>
      
      {progress.trackers.map((t) => <ProgressTrack onDelete={() => dispatch(deleteTracker(t.id))} onDecrement={() => dispatch(decrement(t.id))}  onIncrement={() => dispatch(increment(t.id))} length={10} progress={t.progress} title={t.name} key={t.id} />)}
      
      <div style={{ marginTop: '2rem' }} className="centered-text"><input ref={inputRef} placeholder='Tracker name' type='text'/> <button onClick={addTracker}>Add</button></div>
    </div>
  );
};
