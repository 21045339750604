import DiceRoller from "./dice-roller";

class Oracles {
  newOracleTableTheme = "";
  newOracleTableName = "";
  selectedOracleTable = "";
  selectedOracleSource = "";
  selectedOracleTheme = "";
  editOracleList = "";
  editOracleCursorPosition = 0;
  rollHistory: { Theme: string; Table: string; Result: string }[] = [];
  tables = [
    {
      source: "Ironsworn",
      theme: "Plot",
      title: "Actions",
      core: true,
      prompts: [
        "Scheme",
        "Clash",
        "Weaken",
        "Initiate",
        "Create",
        "Swear",
        "Avenge",
        "Guard",
        "Defeat",
        "Control",
        "Break",
        "Risk",
        "Surrender",
        "Inspect",
        "Raid",
        "Evade",
        "Assault",
        "Deflect",
        "Threaten",
        "Attack",
        "Leave",
        "Preserve",
        "Manipulate",
        "Remove",
        "Eliminate",
        "Withdraw",
        "Abandon",
        "Investigate",
        "Hold",
        "Focus",
        "Uncover",
        "Breach",
        "Aid",
        "Uphold",
        "Falter",
        "Suppress",
        "Hunt",
        "Share",
        "Destroy",
        "Avoid",
        "Reject",
        "Demand",
        "Explore",
        "Bolster",
        "Seize",
        "Mourn",
        "Reveal",
        "Gather",
        "Defy",
        "Transform",
        "Persevere",
        "Serve",
        "Begin",
        "Move",
        "Coordinate",
        "Resist",
        "Await",
        "Impress",
        "Take",
        "Oppose",
        "Capture",
        "Overwhelm",
        "Challenge",
        "Acquire",
        "Protect",
        "Finish",
        "Strengthen",
        "Restore",
        "Advance",
        "Command",
        "Refuse",
        "Find",
        "Deliver",
        "Hide",
        "Fortify",
        "Betray",
        "Secure",
        "Arrive",
        "Affect",
        "Change",
        "Defend",
        "Debate",
        "Support",
        "Follow",
        "Construct",
        "Locate",
        "Endure",
        "Release",
        "Lose",
        "Reduce",
        "Escalate",
        "Distract",
        "Journey",
        "Escort",
        "Learn",
        "Communicate",
        "Depart",
        "Search",
        "Charge",
        "Summon",
        "Ambush",
        "Ban",
        "Spawn",
        "Consecrate",
        "Design",
        "Amplify",
        "Expand",
        "Order",
        "Position",
        "Revile",
        "Engulf",
        "Coalesce",
        "Poison",
        "Dismantle",
        "Infuse",
        "Intertwine",
        "Engender",
        "Forgive",
        "Plunder",
        "Contaminate",
        "Insult",
        "Amend",
        "Emanate",
        "Grieve",
        "Grapple",
        "Veil",
        "Shepherd",
        "Implant",
        "Violate",
        "Strangle",
        "Magnify",
        "Assail",
        "Conceal",
        "Fracture",
        "Harden",
        "Imbue",
        "Isolate",
        "Sculpt",
        "Refresh",
        "Steer",
        "Emit",
        "Stifle",
        "Mutate",
        "Alter",
        "Dissolve",
        "Grow",
        "Unravel",
        "Deteriorate",
        "Condemn",
        "Incite",
        "Conquer",
        "Absorb",
        "Echo",
        "Resolve",
        "Elude",
        "Juxtapose",
        "Rush",
        "Reconfigure",
        "Probe",
        "Instruct",
        "Guide",
        "Repulse",
        "Deter",
        "Overcome",
        "Devour",
        "Arrest",
        "Fuse",
        "Bleed",
        "Ruin",
        "Raise",
        "Twist",
        "Exacerbate",
        "Infringe",
        "Corrode",
        "Atone",
        "Terrorize",
        "Vanquish",
        "Modify",
        "Eclipse",
        "Splinter",
        "Extinguish",
        "Compel",
        "Ignite",
        "Transfigure",
        "Embrace",
        "Govern",
        "Discern",
        "Subdue",
        "Purge",
        "Dominate",
        "Humiliate",
        "Immolate",
        "Deceive",
        "Shame",
        "Demolish",
        "Revitalize",
        "Deviate",
        "Deposit",
        "Embellish",
        "Scatter",
        "Reap",
        "Reposition",
        "Unveil",
        "Assert",
        "Insulate",
        "Unearth",
        "Build",
        "Discover",
        "Mystify",
        "Sabotage",
        "Rend",
        "Devastate",
        "Dissect",
        "Batter",
        "Augment",
        "Empower",
        "Defuse",
        "Repel",
        "Untangle",
        "Obtain",
        "Hinder",
        "Stretch",
        "Boost",
        "Nurture",
        "Catch",
        "Desecrate",
        "Bury",
        "Contort",
        "Steal",
        "Inhibit",
        "Drain",
        "Struggle",
        "Provoke",
        "Tame",
        "Impart",
        "Reconnect",
        "Shake",
        "Excavate",
        "Choke",
        "Crush",
        "Shatter",
        "Illuminate",
        "Ensnare",
        "Diminish",
        "Imitate",
        "Refine",
        "Weave",
        "Forge",
        "Sustain",
        "Expel",
        "Consume",
        "Wreck",
        "Lead",
        "Amass",
        "Climb",
        "Revere",
        "Pollute",
        "Stalk",
        "Disrupt",
        "Entice",
        "Oppress",
        "Smite",
        "Ravage",
        "Starve",
        "Affirm",
        "Reconcile",
        "Soften",
        "Restrain",
        "Expose",
        "Commune",
        "Blame",
        "Revoke",
        "Extract",
        "Smother",
        "Obscure",
        "Advise",
        "Evoke",
        "Strike",
        "Kindle",
        "Subvert",
        "Forbid",
        "Sever",
        "Suffocate",
        "Corrupt",
        "Retreat",
        "Detect",
        "Spurn",
        "Prune",
        "Pierce",
        "Race",
        "Enlarge",
        "Frighten",
        "Fight",
        "Wrest",
        "Strain",
        "Embolden",
        "Erode",
        "Winnow",
        "Coerce",
        "Awaken",
      ],
    },
    {
      source: "Ironsworn",
      theme: "Plot",
      title: "Theme",
      core: true,
      prompts: [
        "Risk",
        "Ability",
        "Price",
        "Ally",
        "Battle",
        "Safety",
        "Survival",
        "Weapon",
        "Wound",
        "Shelter",
        "Leader",
        "Fear",
        "Time",
        "Duty",
        "Secret",
        "Innocence",
        "Renown",
        "Direction",
        "Death",
        "Honor",
        "Labor",
        "Solution",
        "Tool",
        "Balance",
        "Love",
        "Barrier",
        "Creation",
        "Decay",
        "Trade",
        "Bond",
        "Hope",
        "Superstition",
        "Peace",
        "Deception",
        "History",
        "World",
        "Vow",
        "Protection",
        "Nature",
        "Opinion",
        "Burden",
        "Vengeance",
        "Opportunity",
        "Faction",
        "Danger",
        "Corruption",
        "Freedom",
        "Debt",
        "Hate",
        "Possession",
        "Stranger",
        "Passage",
        "Land",
        "Creature",
        "Disease",
        "Advantage",
        "Blood",
        "Language",
        "Rumor",
        "Weakness",
        "Greed",
        "Family",
        "Resource",
        "Structure",
        "Dream",
        "Community",
        "War",
        "Portent",
        "Prize",
        "Destiny",
        "Momentum",
        "Power",
        "Memory",
        "Ruin",
        "Mysticism",
        "Rival",
        "Problem",
        "Idea",
        "Revenge",
        "Health",
        "Fellowship",
        "Enemy",
        "Religion",
        "Spirit",
        "Fame",
        "Desolation",
        "Strength",
        "Knowledge",
        "Truth",
        "Quest",
        "Pride",
        "Loss",
        "Law",
        "Path",
        "Warning",
        "Relationship",
        "Wealth",
        "Home",
        "Strategy",
        "Supply",
        "Treasure",
        "Victory",
        "Energy",
        "Society",
        "Disruption",
        "Imitation",
        "Belief",
        "Blessing",
        "Worth",
        "Failure",
        "End",
        "Anger",
        "Miracle",
        "Competition",
        "Union",
        "Security",
        "Achievement",
        "Desertion",
        "Control",
        "Deceit",
        "Redemption",
        "Sympathy",
        "Vice",
        "Cowardice",
        "Beast",
        "Curse",
        "Struggle",
        "Wrath",
        "Connection",
        "Plot",
        "Patience",
        "Intelligence",
        "Devotion",
        "Cooperation",
        "Vanity",
        "Violation",
        "Rupture",
        "Warband",
        "Exploration",
        "Compassion",
        "Mystery",
        "Glory",
        "Oppression",
        "Ancestry",
        "Atonement",
        "Payment",
        "Betrayal",
        "Illness",
        "Brilliance",
        "Obstacle",
        "Ceremony",
        "Charity",
        "Disagreement",
        "Order",
        "Identity",
        "Company",
        "Disadvantage",
        "Dishonor",
        "Punishment",
        "Skirmish",
        "Transformation",
        "Trick",
        "Offense",
        "Plague",
        "Harm",
        "Proposal",
        "Change",
        "Dignity",
        "Misfortune",
        "Judgment",
        "Life",
        "Opulence",
        "Poverty",
        "Progress",
        "Horror",
        "Courage",
        "Covenant",
        "Shield",
        "Friend",
        "Growth",
        "Skill",
        "Conflict",
        "Dispute",
        "Madness",
        "Chaos",
        "Alliance",
        "Degradation",
        "Passion",
        "Essence",
        "Integrity",
        "Plan",
        "Relic",
        "Intolerance",
        "Support",
        "Extravagance",
        "Bias",
        "Fascination",
        "Crime",
        "Penance",
        "Monument",
        "Monster",
        "Insecurity",
        "Way",
        "Misuse",
        "Pain",
        "Trust",
        "Coup",
        "Riches",
        "Vision",
        "Ruler",
        "Resources",
        "Territory",
        "Inspiration",
        "Ritual",
        "Delay",
        "Triumph",
        "Defense",
        "Artistry",
        "Exchange",
        "Escape",
        "Supplies",
        "Exposure",
        "Mercy",
        "Faith",
        "Dwelling",
        "Justice",
        "Distortion",
        "Machination",
        "Talent",
        "Sickness",
        "Beauty",
        "Doubt",
        "Aid",
        "Influence",
        "Arrogance",
        "Beginning",
        "Haunting",
        "Organization",
        "Wisdom",
        "Heroism",
        "Illusion",
        "Pursuit",
        "Tension",
        "Dominance",
        "Disorder",
        "Negotiation",
        "Hesitation",
        "Zealotry",
        "Oversight",
        "Depravity",
        "Healing",
        "Duel",
        "Tangle",
        "Trial",
        "Waste",
        "Heritage",
        "Disaster",
        "Herd",
        "Retribution",
        "Misery",
        "Culture",
        "Shame",
        "Indulgence",
        "Swarm",
        "Worship",
        "Gratitude",
        "Destruction",
        "Revolt",
        "Value",
        "Defeat",
        "Pact",
        "Negligence",
        "Forgiveness",
        "Separation",
        "Harmony",
        "Rage",
        "Preparation",
        "Agreement",
        "Submission",
        "Cruelty",
        "Division",
        "Fleet",
        "Loyalty",
        "Omen",
        "Discord",
        "Solace",
        "Blockade",
        "Remains",
        "Liberty",
        "Arrival",
        "Grief",
        "Falsehood",
        "Symbol",
        "Suffering",
        "Artifact",
        "Mistrust",
        "Food",
        "Authority",
        "Independence",
        "Refusal",
      ],
    },
    {
      source: "Ironsworn",
      theme: "Location",
      title: "Region",
      core: true,
      prompts: [
        "Barrier Islands",
        "Ragged Coast",
        "Deep Wilds",
        "Flooded Lands",
        "Havens",
        "Hinterlands",
        "Tempest Hills",
        "Veiled Mountains",
        "Shattered Wastes",
        "Elsewhere",
      ],
    },
    {
      source: "Ironsworn",
      theme: "Location",
      title: "Location",
      core: true,
      prompts: [
        "Hideout",
        "Ruin",
        "Mine",
        "Waste",
        "Mystical Site",
        "Path",
        "Outpost",
        "Wall",
        "Battlefield",
        "Hovel",
        "Spring",
        "Layer",
        "Fort",
        "Bridge",
        "Camp",
        "Cairn/Grave",
        "Caravan",
        "Waterfall",
        "Cave",
        "Swamp",
        "Fen",
        "Ravine",
        "Road",
        "Tree",
        "Pond",
        "Fields",
        "Marsh",
        "Steading",
        "Rapids",
        "Pass",
        "Trail",
        "Glade",
        "Plain",
        "Ridge",
        "Cliff",
        "Grove",
        "Village",
        "Moor",
        "Thicket",
        "River Ford",
        "Valley",
        "Bay/Fjord",
        "Foothills",
        "Lake",
        "River",
        "Forest",
        "Coast",
        "Hill",
        "Mountain",
        "Woods",
        "Anomaly",
        "Watch Tower",
        "Cabin",
        "Large Crystal",
        "Encampment",
        "Tower",
        "Longhouse",
        "Crypt",
        "Rope Bridge",
        "Mud Hut",
        "Fire Pit",
        "Orchard",
        "Island",
        "Beach",
        "Tundra",
        "Salt Flats",
        "Large Rock Formations",
        "Mesa",
        "Butte",
        "Game Trail",
        "Wild Berry Patch",
        "Alluvial Fan",
        "Wildflower Field",
        "Underground River",
        "Crystal Cave",
        "Glacier",
        "Painted Hills",
        "Basin",
        "Ice Cave",
        "Underground Lake Entrance",
        "Steppe",
        "Mount",
        "Overhang",
        "Hunting Grounds",
        "Sparse Woods",
        "Thick Undergrowth",
        "Canopied Forest",
        "Escarpment",
        "Crop Field",
        "Mounds",
        "Tiered Farmland",
        "Stone Wall",
        "Scorched Earth",
        "Cavern System",
        "Desert",
        "Canyon",
        "Forest Fire",
        "Landslide",
        "Flash Flood",
        "Lean-To",
        "Yurt",
        "Hut",
        "Farm",
        "Standing",
        "Stones",
        "Homestead",
        "Tunnel",
        "Shrine",
        "Stone Pillar",
        "Iron Pillar",
        "Volcano",
        "Monastery",
        "Oasis",
        "Dunes",
        "Mausoleum",
        "Stronghold",
        "Sacred Place",
        "Giant Boulders",
        "River Rapids",
        "Lava Tube",
        "Monument",
        "Ledge",
        "Savannah",
        "Sinkhole",
        "Pasture",
        "Pit",
        "Gravel Incline",
        "Rock Incline",
        "Summit",
        "Peak",
        "Plateau",
        "Gorge",
        "Chasm",
        "Stone Bridge",
        "Rock Spires",
        "Dry Riverbed",
        "Cavern",
        "Hot Springs",
        "Hollow",
        "Bog",
        "Switch Backs",
        "Quicksand",
        "Tar Pit",
        "Deciduous Forest",
        "Hilltop",
        "Jungle",
        "Conifer Forest",
        "Field",
        "Clearing",
        "Scrubland",
        "Stream",
        "Grassland",
      ],
    },
    {
      source: "Ironsworn",
      theme: "Location",
      title: "Coastal Waters Location",
      core: true,
      prompts: [
        "Fleet",
        "Sargassum",
        "Flotsam",
        "Mystical Site",
        "Lair",
        "Wreck",
        "Harbor",
        "Ship",
        "Rocks",
        "Fjord",
        "Estuary",
        "Cove",
        "Bay",
        "Ice",
        "Island",
        "Open Water",
        "Anomaly",
        "Iceberg",
        "White Cliffs",
        "Sandy Beach",
        "Inlet",
        "Atoll",
        "Ice Floe",
        "Glacier",
        "Breeding Waters",
        "Wetlands",
        "Thermal Vents ",
        "Port",
        "Sea Cave ",
        "Barge",
        "Lighthouse ",
        "River Delta",
        "Solitary Dock",
        "Fishing Village",
        "Barrier Reef",
        "Pirate Camp ",
        "Glacial Wall",
        "Jetsam",
        "Derelict",
        "Lava Flow",
        "Storm Debris",
        "Coral Reef",
        "Natural Arches",
        "Peninsula",
        "Archipelago",
        "Rocky Beach",
        "Island Chain",
        "Stacks",
        "Sand Bars",
      ],
    },
    {
      source: "Ironsworn",
      theme: "Location",
      title: "Location Descriptor",
      core: true,
      prompts: [
        "High",
        "Remote",
        "Exposed",
        "Small",
        "Broken",
        "Diverse",
        "Rough",
        "Dark",
        "Shadowy",
        "Contested",
        "Grim",
        "Wild",
        "Fertile",
        "Blocked",
        "Ancient",
        "Perilous",
        "Hidden",
        "Occupied",
        "Rich",
        "Big",
        "Savage",
        "Defended",
        "Withered",
        "Mystical",
        "Inaccessible",
        "Protected",
        "Abandoned",
        "Wide",
        "Foul",
        "Dead",
        "Ruined",
        "Barren",
        "Cold",
        "Blighted",
        "Low",
        "Beautiful",
        "Abundant",
        "Lush",
        "Flooded",
        "Empty",
        "Strange",
        "Corrupted",
        "Peaceful",
        "Forgotten",
        "Expansive",
        "Settled",
        "Dense",
        "Civilized",
        "Desolate",
        "Isolated",
        "Bustling",
        "Compact",
        "Fantastic",
        "Huge",
        "Strategic",
        "Hazardous",
        "Dangerous",
        "Exotic",
        "Crumbling",
        "Dilapidated",
        "Decayed",
        "Unsafe",
        "Secret",
        "Tropical",
        "Frozen",
        "Mysterious",
        "Undesirable",
        "Poor",
        "Vibrant",
        "Bright",
        "Tamed",
        "Depleted",
        "Guarded",
        "Thin",
        "Lively",
        "Eerie",
        "Warm",
        "Narrow",
        "Windy",
        "Calm",
        "Creepy",
        "Crowded",
        "Deserted",
        "Dry",
        "Wet",
        "Enchanted",
        "Majestic",
        "Spacious",
        "Tranquil",
        "Natural",
        "Noisy",
        "Quiet",
        "Stormy",
        "Unique",
        "Traditional",
        "Lonely",
        "Smooth",
        "Colossal",
        "Giant",
        "Tiny",
        "Chilly",
        "Sharp",
        "Chilling ",
        "Steep",
        "Unnerving ",
        "Terrible",
        "Horrifying ",
        "Overgrown",
        "Crooked",
        "Bountiful",
        "Dim",
        "Crude",
        "Filthy",
        "Primitive",
        "Dirty",
        "Odd",
        "Dingy",
        "Endangered",
        "Harsh",
        "Hostile",
        "Elderly",
        "Neglected",
        "Old",
        "Pristine",
        "Young",
        "Toxic",
        "Treacherous",
        "Unsavory",
        "Cluttered",
        "Bleak",
        "Somber",
        "Gloomy",
        "Uninhabited",
        "Disgusting",
        "Nightmarish",
        "Haunted",
        "Turbulent",
        "Ugly",
        "Grand",
        "Enigmatic",
        "Complex",
        "Sacred",
        "Hospitable",
        "Shoddy",
        "Timeless",
        "Untouched",
        "Vulnerable",
        "Open",
        "Closed",
        "Violent",
      ],
    },
    {
      source: "Ironsworn",
      theme: "Location",
      title: "Settlement Names",
      core: true,
      prompts: [
        "Highmount",
        "Brackwater",
        "Frostwood",
        "Redcrest",
        "Grimtree",
        "Stoneford",
        "Deepwater",
        "Whitefall",
        "Graycliff",
        "Three Rivers",
        "Whitebridge",
        "Lonefort",
        "Highcairn",
        "Redhall",
        "Darkwell",
        "Timberwall",
        "Stonetower",
        "Thornhall",
        "Cinderhome",
        "Fallowfield",
        "Ravencliff",
        "Bearmark",
        "Wolfcrag",
        "Eaglespire",
        "Wyvern's Rest",
        "Boarwood",
        "Foxhollow",
        "Elderwatch",
        "Elkfield",
        "Dragonshadow",
        "Swordbreak",
        "Fool's Fall",
        "Firstmeet",
        "Brokenhelm",
        "Mournhaunt",
        "Olgar's Stand",
        "Lostwater",
        "Rojirra's Lament",
        "Lastmarch",
        "Rockfall",
        "Abon",
        "Daveza",
        "Damula",
        "Essus",
        "Sina",
        "Kazerra",
        "Khazu",
        "Sova",
        "Nabuma",
        "Tiza",
        "Winterhome",
        "Windhaven",
        "Stormrest",
        "Bleakfrost",
        "Springtide",
        "Duskmoor",
        "Frostcrag",
        "Springbrook",
        "Icebreak",
        "Summersong",
        "Ironhome",
        "New Arkesh",
        "Kei's Hall",
        "Elisora",
        "Blackhelm",
        "Elfbrook",
        "Nessana",
        "Ghostwalk",
        "Hope",
        "Forsaken",
      ],
    },
    {
      source: "Ironsworn",
      theme: "Location",
      title: "Settlement Name Prefixes",
      core: true,
      prompts: [
        "Bleak",
        "Green",
        "Wolf",
        "Raven",
        "Gray",
        "Red",
        "Axe",
        "Great",
        "Wood",
        "Low",
        "White",
        "Storm",
        "Black",
        "Mourn",
        "New",
        "Stone",
        "Grim",
        "Lost",
        "High",
        "Rock",
        "Shield",
        "Sword",
        "Frost",
        "Thorn",
        "Long",
        "Potters",
        "Rain",
        "Elk",
        "Boar",
        "Fleet",
        "Mead",
        "Bridge",
        "Snow",
        "Crow",
        "Owl",
        "Hammer",
        "Wrath",
        "Cliff",
        "North",
        "South",
        "East",
        "West",
        "Summer",
        "Winter",
        "Fall",
        "Spring",
        "Iron",
        "Sweet",
        "Wind",
        "Eagle",
        "Bear",
        "Ash",
        "Pine",
        "Oak",
        "Timber",
        "Gold",
        "Silver",
        "Hill",
        "Hawk",
        "Hound",
        "Lion",
        "Wyrm",
        "Vintner",
        "Little",
        "Fox",
        "Dry",
        "Moss",
        "Brittle",
        "Bitter",
        "Boulder",
        "Dawn",
        "Stag",
        "Sleet",
        "Wilde",
        "Elder",
      ],
    },
    {
      source: "Ironsworn",
      theme: "Location",
      title: "Settlement Name Suffixes",
      core: true,
      prompts: [
        "moor",
        "ford",
        "crag",
        "watch",
        "hope",
        "wood",
        "ridge",
        "stone",
        "haven",
        "fall(s)",
        "river",
        "field",
        "hill",
        "bridge",
        "mark",
        "cairn",
        "land",
        "hall",
        "mount",
        "rock",
        "brook",
        "barrow",
        "stead",
        "home",
        "wick",
        "ton",
        "don",
        "ville",
        "thorpe",
        "stage",
        "rest",
        "fold",
        "crest",
        "tun",
        "bourne",
        "gap",
        "valley",
        "peak",
        "dale",
        "nest",
        "tree",
        "kirk",
        "bay",
        "henge",
        "horn",
        "ness",
        "Willow",
        "beck",
        "thwaite",
        "ham",
        "nock",
        "Worth",
        "holm",
        "haugr",
        "brekkr",
        "borough",
        "dalr",
        "by",
        "sham",
        "well",
        "crossing",
        "steinn",
        "smith",
        "grave",
        "keld",
        "Wen",
        "grove",
        "fell",
        "gate",
        "mon",
        "dore",
        "torp",
        "loft",
        "vik",
        "vale",
      ],
    },
    {
      source: "Ironsworn",
      theme: "Plot",
      title: "Settlement Trouble",
      core: true,
      prompts: [
        "Outsiders rejected",
        "Dangerous discovery",
        "Dreadful omens",
        "Natural disaster",
        "Old wounds reopened",
        "Important object is lost",
        "Someone is captured",
        "Mysterious phenomenon",
        "Revolt against a leader",
        "Vengeful outcast",
        "Rival settlement",
        "Nature strikes back",
        "Someone is missing",
        "Production halts",
        "Mysterious murders",
        "Debt comes due",
        "Unjust leadership",
        "Disastrous accident",
        "In league with the enemy",
        "Raiders prey on the weak",
        "Cursed past",
        "An innocent is accused",
        "Corrupted by dark magic",
        "Isolated by brutal weather",
        "Provisions are scarce",
        "Sickness run amok",
        "Allies become enemies",
        "Attack is imminent",
        "Lost caravan",
        "Dark secret revealed",
        "Urgent expedition",
        "A leader falls",
        "Families in conflict",
        "Incompetent leadership",
        "Reckless warmongering",
        "Beast on the hunt",
        "Betrayed from within",
        "Broken truce",
        "Wrathful haunt",
        "Conflict with a firstborn",
        "Trade route blocked",
        "In the crossfire",
        "Stranger causes discord",
        "Important event threatened",
        "Dangerous tradition",
        "Mystic's power surges uncontrollably",
        "Storm damaged structures",
        "Authoritarian leader ",
        "Dark cult has control",
        "Forced labor camps ",
        "Area slowly flooding",
        "Contest of arms gone wrong",
        "Treaty signing interrupted",
        "Mine collapse",
        "Series of kidnapping",
        "Protection racket ",
        "Horrors roam the night",
        "Incoming swarm ",
        "Herds being hunted",
        "Mystical blight",
        "Harvest lost",
        "Resources depleted ",
        "Forest fire",
        "Consequences of a forsaken vow",
        "Rock formation collapse",
        "Water source dried up ",
        "Lack of iron",
        "Important object broken ",
        "Winter is early",
        "Mystic declares a dark prophecy",
        "New settlement discovered",
        "Trade route plagued by bandits",
        "Blizzard catches them unprepared",
        "Trade caravan being ambushed right now",
        "Vengeful mystic's curse",
        "Mob hunts a mystic ",
        "Sacred object is lost",
        "Religious leader in conflict with overseer",
        "Zealots in control",
        "Town bully",
        "Residents too infirm to care for themselves",
        "Sacrifices demanded",
        "Turning a blind eye",
        "Allies abandon a cause",
        "Political schism",
        "Ironsworn rejected",
        "Many here have forsaken vows",
        "Reckless upstart",
        "Firstborn take offense",
        "Mystic ceremony interrupted",
        "Roll twice",
        "Falling star lands explosively",
        "Epic beast on the move",
        "Rune-covered monolith appears in the night",
        "Stampede",
        "Permafrost spreading",
        "Livestock on the loose",
        "Bounty hunter seeks an innocent",
        "Citizens trapped in the wilderness",
        "Strange formation carved in the hillside",
        "Animal traps have been destroyed",
        "Local graveyard has been desecrated",
        "Mystic trapped in the throes of a ritual",
        "Celestial event causes hysteria",
        "All the children have disappeared",
        "Sinkhole damages settlement",
        "Rebuilding from a recent battle",
        "Cavem of creatures unearthed",
        "Citizen who was lost returns but can't speak",
        "Inexplicable deaths ",
        "Pack of hunting animals",
        "Need to create safe trade route",
        "Grief-stricken over significant loss",
        "Truce threatened",
        "Crops are ablaze",
        "Elaborate hoax",
        "Dueling mystics",
        "Collapsed bridge",
        "Game hunters injured",
        "Ancestral weapon lost ",
        "Surge of refugees",
        "Pillar of iron discovered ",
        "Sickness hits livestock",
        "Large creature burrows under structures",
        "Vermin infestation",
        "Water source poisoned",
        "Untrusted criminal seeks redemption",
        "Settlement's chief defender has died",
        "Forced to quarter bandits",
        "Leader has been poisoned",
        "Assassination attempt on leader",
        "Smithing forge destroyed",
        "Beacon fires go out",
        "Need impartial judge for a trial",
        "Territory dispute",
        "Supplies stolen",
      ],
    },
    {
      source: "Ironsworn",
      theme: "Character",
      title: "Character Role",
      core: true,
      prompts: [
        "Criminal",
        "Healer",
        "Bandit",
        "Guide",
        "Performer",
        "Miner",
        "Mercenary",
        "Outcast",
        "Vagrant",
        "Forester",
        "Traveler",
        "Mystic",
        "Priest",
        "Sailor",
        "Pilgrim",
        "Thief",
        "Adventurer",
        "Forager",
        "Leader",
        "Guard",
        "Artisan",
        "Scout",
        "Herder",
        "Fischer",
        "Warrior",
        "Hunter",
        "Raider",
        "Trader",
        "Farmer",
        "Unusual Role",
        "Alchemist",
        "Historian",
        "Scribe",
        "Baker",
        "Minstrel",
        "Apothecary",
        "Chandler",
        "Rope Maker",
        "Acolyte",
        "Clergy",
        "Cooper",
        "Equine Trainer",
        "Falconer",
        "Farrier",
        "Bowyer/Fletcher",
        "Messenger",
        "Herbalist",
        "Brewer",
        "Animal Healer",
        "Shipwright",
        "Mason",
        "Shaman",
        "Midwife",
        "Fur Trader",
        "Livestock Farmer",
        "Carpenter",
        "Blacksmith",
        "Shepherd",
        "Tanner",
        "Cobbler",
        "Explorer",
        "Papermaker",
        "Book Binder",
        "Thatcher",
        "Tinkerer",
        "Soldier",
        "Stable Hand",
        "Tutor",
        "Tax Collector",
        "Jeweler",
        "Weaponsmith",
        "Armorsmith",
        "Potter",
        "Bone Carver",
        "Seer",
        "Overseer",
        "Bodyguard",
        "Wheel Maker",
        "Watchman",
        "Cook",
        "Trapper",
        "Bannerman",
        "Tracker",
        "Weaver",
        "Urchin",
        "Tailor",
        "Miller",
        "Merchant",
      ],
    },
    {
      source: "Ironsworn",
      theme: "Character",
      title: "Character Goal",
      core: true,
      prompts: [
        "Obtain an object",
        "Make an agreement",
        "Build a relationship",
        "Undermine a relationship",
        "Seek a truth",
        "Pay a debt",
        "Refute a falsehood",
        "Harm a rival",
        "Cure an ill",
        "Find a person",
        "Find a home",
        "Sieze power",
        "Restore a relationship",
        "Create an item",
        "Travel to a place",
        "Secure provisions",
        "Rebel against power",
        "Collect a debt",
        "Protect a secret",
        "Spread faith",
        "Enrich themselves",
        "Protect a person",
        "Protect the status quo",
        "Advance status",
        "Defend a place",
        "Avenge a wrong",
        "Fulfill a duty",
        "Gain knowledge",
        "Prove worthiness",
        "Find redemption",
        "Resolve a dispute",
        "Bring law and order",
        "Protect the innocent",
        "Feel personal accomplishment",
        "Rescue a person",
        "Get revenge",
        "Gain fame and glory",
        "Acquire riches",
        "Find unique artifacts",
        "Deepen mystic power",
        "Fight the good fight",
        "Explore and discover",
        "Advance a settlement",
        "Unite settlements",
        "Leave their mark",
        "Personal development",
        "Have food to eat",
        "Live in relative luxury",
        "Serve others",
        "Bring forth justice",
        "Advance religion",
        "Make many small impacts",
        "Build a better life",
        "Collect unique art",
        "Gain power and influence",
        "Solve ancient mystery",
        "Find safe place to live",
        "Gain followers",
        "Become an overseer",
        "Be loved by all",
        "Cure disease",
        "Slay a creature",
        "Bring balance",
        "Hedonism",
        "Raise a family",
        "Help the helpless",
        "Voice for the voiceless",
        "Raise others from poverty",
        "Seek pleasure",
        "Destroy an item",
        "Find a soulmate",
        "Teach others",
        "Be a hero",
        "Liberate those in bondage",
        "Bring order to chaos",
        "Achieve moral perfection",
        "Achieve spiritual enlightenment",
        "Oppress others",
        "Scheme machinations",
        "Serve the rightful ruler",
        "Feed an addiction",
        "Discover the truth of their past",
        "Learn of the Old World",
        "Make contact with First Born",
        "Survive the winter",
        "Change the system",
        "Defeat a foe",
        "Be the best",
        "Avoid ridicule",
        "Be left alone",
        "Stay true to friends and family",
        "Win affection",
        "Defeat romantic rival",
        "Find settlement resources",
        "Seek forgiveness",
      ],
    },
    {
      source: "Ironsworn",
      theme: "Character",
      title: "Character Descriptor",
      core: true,
      prompts: [
        "Stoic",
        "Attractive",
        "Passive",
        "Aloof",
        "Affectionate",
        "Generous",
        "Smug",
        "Armed",
        "Clever",
        "Brave",
        "Ugly",
        "Sociable",
        "Doomed",
        "Connected",
        "Bold",
        "Jealous",
        "Active",
        "Angry",
        "Suspicious",
        "Hostile",
        "Hardhearted",
        "Successful",
        "Talented",
        "Experienced",
        "Deceitful",
        "Ambitious",
        "Aggressive",
        "Conceited",
        "Proud",
        "Stern",
        "Dependent",
        "Wary",
        "Strong",
        "Inciteful",
        "Dangerous",
        "Quirky",
        "Cheery",
        "Disfigured",
        "Intolerant",
        "Skilled",
        "Stingy",
        "Timid",
        "Insensitive",
        "Wild",
        "Bitter",
        "Cunning",
        "Remorseful",
        "Kind",
        "Charming",
        "Oblivious",
        "Critical",
        "Cautious",
        "Resourceful",
        "Weary",
        "Wounded",
        "Anxious",
        "Powerful",
        "Athletic",
        "Driven",
        "Cruel",
        "Quiet",
        "Honest",
        "Infamous",
        "Dying",
        "Reclusive",
        "Artistic",
        "Disabled",
        "Confused",
        "Manipulative",
        "Relaxed",
        "Smug",
        "Confident",
        "Weak",
        "Friendly",
        "Wise",
        "Influential",
        "Young",
        "Adventurous",
        "Oppressed",
        "Vengeful",
        "Cooperative",
        "Armoured",
        "Apathetic",
        "Determined",
        "Loyal",
        "Sick",
        "Religious",
        "Selfish",
        "Old",
        "Fervent",
        "Violent",
        "Agreeable",
        "Hot-tempered",
        "Stubborn",
        "Incompetent",
        "Greedy",
        "Cowardly",
        "Obsessed",
        "Careless",
        "Ironsworn",
        "Sympathetic",
        "Shy",
        "Meek",
        "Plucky",
        "Vain",
        "Creepy",
        "Belligerent",
        "Irresponsible",
        "Fearless",
        "Dogmatic",
        "Trustworthy",
        "Zealous",
        "Foolhardy",
        "Sensitive",
        "Thoughtful",
        "Persistent",
        "Tidy",
        "Rude",
        "Erratic",
        "Gossipy",
        "Flirtatious",
        "Pragmatic",
        "Vicious",
        "Frank",
        "Brusque",
        "Mean",
        "Bothersome",
        "Quick-witted",
        "Arrogant",
        "Cynical",
        "Affable",
        "Abrupt",
        "Unorganized",
        "Clinging",
        "Pious",
        "Rational",
        "Warmhearted",
        "Sleepy",
        "Intelligent",
        "Reliable",
        "Pompous",
        "Faithful",
        "Impulsive",
        "Emotional",
        "Patronizing",
        "Sneaky",
        "Dramatic",
        "Eccentric",
        "Harsh",
        "Nosy",
        "Charismatic ",
        "Coldhearted",
        "Gullible",
        "Tough",
        "Touchy",
        "Gregarious",
        "Passionate",
        "Fixated",
        "Superficial",
        "Reserved",
        "Sad",
        "Romantic",
        "Scary",
        "Miserly",
        "Courageous",
        "Eager to Please",
        "Contrary",
        "Ill Tempered",
        "Calm",
        "Practical",
        "Easily Impressed ",
        "Meddlesome",
        "Overcritical",
        "Sincere",
        "Obstinate",
        "Nervous",
        "Squeamish",
        "Curious",
        "Organized",
        "Compulsive",
        "Staightforward ",
        "Patient",
        "Alert",
        "Clumsy",
        "Hard Working ",
        "Calculating",
        "Nice",
        "Addicted",
        "Lost in the Past ",
        "Liberal",
        "Discreet",
        "Helpful",
        "Fickle",
        "Prejudiced",
        "Reactionary",
        "Witty",
        "Understanding ",
        "Thoughtless",
        "Sarcastic",
        "Reckless",
        "Grief-stricken ",
        "Pontificating",
        "Cocky",
        "Inconsiderate",
        "Untrustworthy ",
        "Boring",
        "Moody",
        "Closed Minded",
        "Inflexible",
        "Decisive",
        "Impatient",
        "Bashful",
        "Pessimistic",
        "Carefree",
        "Sensible",
        "Ruthless",
        "Compassionate ",
        "Boastful",
        "Forceful",
        "Amusing",
        "Frugal",
        "Funny",
        "Open Minded ",
        "Insane",
        "Quarrelsome ",
        "Adaptable",
        "Faithless",
        "Diligent",
        "Tired",
        "Blunt",
        "Assertive",
        "Short-tempered",
        "Superstitious ",
        "Disloyal",
        "Vindictive",
        "Energetic",
        "Self-centered ",
        "Humble",
        "Resentful",
        "Courteous",
        "Weak-willed ",
        "Communicative",
        "Morose",
        "Unreliable",
        "Indecisive",
        "Sickening",
        "Happy",
        "Conscientious",
        "Loving",
        "Immoral",
        "Modest",
        "Chaotic",
        "Indiscreet",
        "Dishonest",
        "Amicable",
        "Cantankerous",
        "Unkind",
        "Considerate",
        "Unpredictable",
        "Judgmental",
        "Possessive",
        "Civil",
        "Lazy",
        "Complacent",
        "Forthcoming",
        "Prideful",
        "Awestruck",
        "Devout",
        "Narcissistic",
        "Optimistic",
        "Paranoid",
        "Flexible",
        "Unassuming",
        "Childish",
        "Ignorant",
        "Polite",
        "Fanatical",
        "Grumpy",
        "Mysterious",
        "Finicky",
        "Immature",
        "Competent",
        "Tacky",
        "Absent-minded",
        "Secretive",
        "Depressing",
        "Tactless",
        "Easygoing",
        "Spoiled",
        "Deadly",
        "Terrifying",
        "Benevolent",
        "Pro-active",
        "Conservative",
        "Naïve",
        "Gentle",
        "Impartial",
        "Boisterous",
        "Callous",
        "Diplomatic",
      ],
    },
    {
      source: "Ironsworn",
      theme: "Character",
      title: "Character Disposition",
      core: true,
      prompts: [
        "Helpful",
        "Indifferent",
        "Demanding",
        "Friendly",
        "Suspicious",
        "Unfriendly",
        "Cooperative",
        "Wanting",
        "Threatening",
        "Curious",
        "Desperate",
        "Hostile",
        "Joyful",
        "Excited",
        "Agreeable",
        "Introspective",
        "Bored",
        "Guarded",
        "Veiled",
        "Needy",
        "Fearful",
        "Derisive",
        "Disgusted",
        "Hateful",
        "Grateful",
        "Optimistic",
        "Interested",
        "Distracted",
        "Confused",
        "Annoyed",
        "Obscure",
        "Bitter",
        "Nervous",
        "Angry",
        "Disapproving",
        "Combative",
      ],
    },
    {
      source: "Ironsworn",
      theme: "Character",
      title: "NPC Conversation",
      core: true,
      prompts: [
        "Their own heritage",
        "The future of the community ",
        "A recent change in their own family",
        "The heritage of a Player ",
        "The heritage of the community ",
        "Reported sightings of the First Born",
        "The heritage of an NPC ",
        "The value of experience ",
        "A recent change in the family of an NPC",
        "A source of wealth",
        "The most valuable experiences ",
        "Information that has recently been lost",
        "The distribution of wealth ",
        "Rumors of a players's past ",
        "Information that was lost when coming from the Old World",
        "Where the power lies ",
        "Rumors of an NPC's past ",
        "Information that has recently been discovered",
        "Local warbands",
        "Important social connections ",
        "A player secret that has been made known",
        "Powerful people ",
        "New life",
        "An enemy secret that has been made known",
        "Ingenious or outlandish ideas ",
        "The current leadership ",
        "Gossip about shamed Ironlanders and forsaken vows",
        "Items of importance",
        "Important political connections ",
        "Why the leadership needs to change",
        "Legends of relics",
        "Recent political changes ",
        "History from before the Ironlanders",
        "Legends of heroic deeds ",
        "A specific location",
        "Particular skills of a trade, craft, or occupation",
        "Famous people",
        "General knowledge of a region ",
        "Particular equipment of a trade, craft, or occupation",
        "Famous places",
        "The acquisition of knowledge ",
        "Common knowledge about an enemy",
        "The quickest way to fame Current events",
        "Small jobs or sides quests that need to be done",
        "How to curry favor ",
        "Upcoming events",
        "A recent inaction and the consequences",
        "Shifting political alliances A personal injury",
        "Difficulties in settling the Ironlands",
        "Useful contacts",
        "Their own failures",
        "The decline of the Old World as told to them in childhood",
        "A significant death",
        "The failures of a player",
        "A minor plot point involving an iron vow",
        "The culture of the community ",
        "The failures of an NPC ",
        "A major plot point involving an iron vow",
      ],
    },
    {
      source: "Ironsworn",
      theme: "Character",
      title: "NPC Knowledge Type",
      core: true,
      prompts: [
        "The location of",
        "The identity of",
        "Positive news about",
        "Negative news about",
        "A positive change in",
        "A negative change in",
        "A connection between an NPC and",
        "A connection between a player and",
        "A connection between an antagonist and",
        "A significant insight related to",
        "Historical background knowledge about",
        "An ambush concerning",
        "An alteration of",
        "A physical loss involving",
        "A mental loss involving",
        "An emotional loss involving",
        "A spiritual loss involving",
        "A financial loss involving",
        "The loss of an ability involving",
        "The loss of authority involving",
        "A material loss involving",
        "A loss of influence involving",
        "A loss of opportunity involving",
        "A physical boon involving",
        "A mental boon involving",
        "An emotional boon involving",
        "A spiritual boon involving",
        "A financial boon involving",
        "The acquisition of an ability involving",
        "The acquisition of authority involving",
        "A material boon involving",
        "A gain in influence involving",
        "An additional opportunity involving",
        "The truth is the exact opposite of what the player thought about",
      ],
    },
    {
      source: "Ironsworn",
      theme: "Character",
      title: "NPC Knowledge Topic",
      core: true,
      prompts: [
        "an enemy spy",
        "an enemy servant",
        "an enemy leader",
        "an enemy stronghold",
        "a secret enemy hideout",
        "a safe location for the players",
        "a dangerous location for the players",
        "an enemy who is now an ally",
        "a traitor to the players",
        "an enemy's current plan",
        "an enemy's future plan",
        "a necessary artifact for fulfilling a vow",
        "a benefactor for the players",
        "a distant location",
        "the current setting",
        "a main antagonist",
        "a beloved NPC",
        "a combative NPC",
        "a single player",
        "the players as a whole",
        "a despised NPC",
        "a necessary object to complete a vow",
        "the road or passage to the next location",
        "a special status for a player",
        "a special status for an NPC",
        "a special status for a main antagonist",
        "the current short-term goal",
        "a group supportive to the players",
        "an extreme or epic vow",
        "a person with important information about a formidable or lower vow",
        "an oppositional group that is not a main antagonist",
        "a person with important information about an extreme or epic vow",
        "a previously unknown character connected to the plot",
        "a foundational truth of the world",
      ],
    },
    {
      source: "Ironsworn",
      theme: "Names",
      title: "Ironlander Names",
      core: true,
      prompts: [
        "Solana",
        "Keelan",
        "Cadigan",
        "Sola",
        "Kodroth",
        "Kione",
        "Katja",
        "Tio",
        "Artiga",
        "Eos",
        "Bastien",
        "Elli",
        "Maura",
        "Haleema",
        "Abella",
        "Morter",
        "Wulan",
        "Mai",
        "Farina",
        "Pearce",
        "Wynne",
        "Haf",
        "Aeddon",
        "Khinara",
        "Milla",
        "Nakata",
        "Kynan",
        "Kiah",
        "Jaggar",
        "Beca",
        "Ikram",
        "Melia",
        "Sidan",
        "Deshi",
        "Tessa",
        "Sibila",
        "Morien",
        "Mona",
        "Padma",
        "Avella",
        "Naila",
        "Lio",
        "Cera",
        "Ithela",
        "Zhan",
        "Kaivan",
        "Valeri",
        "Hirsham",
        "Pemba",
        "Edda",
        "Lestara",
        "Lago",
        "Elstan",
        "Saskia",
        "Kabeera",
        "Caldas",
        "Nisus",
        "Serene",
        "Chenda",
        "Themon",
        "Erin",
        "Alban",
        "Parcell",
        "Jelma",
        "Willa",
        "Nadira",
        "Gwen",
        "Amara",
        "Masias",
        "Kanno",
        "Razeena",
        "Mira",
        "Perella",
        "Myrick",
        "Qamar",
        "Kormak",
        "Zura",
        "Zanita",
        "Brynn",
        "Tegan",
        "Pendry",
        "Quinn",
        "Fanir",
        "Glain",
        "Emelyn",
        "Kendi",
        "Althus",
        "Leela",
        "Ishana",
        "Flint",
        "Delkash",
        "Nia",
        "Nan",
        "Keeara",
        "Katania",
        "Morell",
        "Temir",
        "Bas",
        "Sabine",
        "Tallus",
        "Segura",
        "Gethin",
        "Bataar",
        "Basira",
        "Joa",
        "Glynn",
        "Toran",
        "Arasen",
        "Kuron",
        "Griff",
        "Owena",
        "Adda",
        "Euros",
        "Kova",
        "Kara",
        "Morgan",
        "Nanda",
        "Tamara",
        "Asha",
        "Delos",
        "Torgan",
        "Makari",
        "Selva",
        "Kimura",
        "Rhian",
        "Tristan",
        "Siorra",
        "Sayer",
        "Cortina",
        "Vesna",
        "Kataka",
        "Keyshia",
        "Mila",
        "Lili",
        "Vigo",
        "Sadia",
        "Malik",
        "Dag",
        "Kuno",
        "Reva",
        "Kai",
        "Kalina",
        "Jihan",
        "Hennion",
        "Abram",
        "Aida",
        "Myrtle",
        "Nekun",
        "Menna",
        "Tahir",
        "Sarria",
        "Nakura",
        "Akiya",
        "Talan",
        "Mattick",
        "Okoth",
        "Khulan",
        "Verena",
        "Beltran",
        "Del",
        "Ranna",
        "Alina",
        "Muna",
        "Mura",
        "Torrens",
        "Yuda",
        "Nazmi",
        "Ghalen",
        "Sarda",
        "Shona",
        "Kalidas",
        "Wena",
        "Sendra",
        "Kori",
        "Setara",
        "Lucia",
        "Maya",
        "Reema",
        "Yorath",
        "Rhoddri",
        "Shekhar",
        "Servan",
        "Reese",
        "Kenrick",
        "Indirra",
        "Giliana",
        "Jebran",
        "Kotama",
        "Fara",
        "Katrin",
        "Namba",
        "Lona",
        "Taylah",
        "Kato",
        "Esra",
        "Eleri",
        "Irsia",
        "Kayu",
        "Bevan",
        "Chandra",
        "Rafe",
        "Eydis",
        "Snorri",
        "Orvar",
        "Ragnheid",
        "Tarben",
        "Dagrun",
        "Bragi",
        "Hakon",
        "Guiscard",
        "Esko",
        "Alvar",
        "Turid",
        "Gulla",
        "Gudrun",
        "Gunnhildr",
        "Aki",
        "Skuld",
        "Gustavo",
        "Loke",
        "Vidar",
        "Gudlaug",
        "Hroaldr",
        "Asgeirr",
        "Svea",
        "Trygve",
        "Tora",
        "Audhild",
        "Liva",
        "Tyra",
        "Haldor",
        "Magnhildr",
        "Esbjorn",
        "Stigr",
        "Steinarr",
        "Ingrid",
        "Embla",
        "Ingebjorg",
        "Hallr",
        "Hallbjorn",
        "Brynhildr",
        "Solveig",
        "Oddvarr",
        "Astridr",
        "Randi",
        "Dagmar",
        "Indridi",
        "Idunn",
        "Hildr",
        "Olli",
        "Ingvar",
        "Adalsteinn",
        "Borghild",
        "Hjalmar",
        "Laila",
        "Oili",
        "Hege",
        "Torhild",
        "Siegfried",
        "Esben",
        "Leif",
        "Halvor",
        "Espen",
        "Hilda",
        "Viskrhordr",
        "Piret",
        "Ulfr",
        "Inge",
        "Hjordis",
        "Sorley",
        "Baard",
        "Sven",
        "Skadi",
        "Leif",
        "Felagi",
        "Hugleikr",
        "Herleif",
        "Asbjorn",
        "Karr",
        "Bjarni",
        "Aric",
        "Endre",
        "Tasgall",
        "Hrafnhildr",
        "Torben",
        "Birkir",
        "Rollo",
        "Olavi",
        "Hagen",
        "Steinnunnr",
        "Inguna",
        "Havard",
        "Birgitta",
        "Elva",
        "Erlingr",
        "Erlendur",
        "Regin",
        "Erik",
        "Ivor",
        "Finnr",
        "Heimir",
        "Arvid",
        "Sassa",
        "Arnbjorg",
        "Gjord",
        "Elwes",
        "Oline",
        "Haakon",
        "Askr",
        "Gota",
        "Edda",
        "Yngvarr",
        "Ari",
        "Birgir",
        "Frodr",
        "Oswald",
        "Heidrun",
        "Gudini",
        "Jorun",
        "Nanna",
        "Alf",
        "Sigrun",
        "Folki",
        "Oddr",
        "Aslaug",
        "Sigrid",
        "Nerthus",
        "Herta",
        "Ove",
        "Keld",
        "Kerr",
        "Magni",
        "Aleifr",
        "Torunn",
        "Eskil",
        "Eir",
        "Tordis",
        "Elof",
        "Bjork",
        "Eivind",
        "Freyr",
        "Olga",
        "Ealdwine",
        "Olafur",
        "Erkki",
        "Uilliam",
        "Geirr",
        "Stigandr",
        "Flaeming",
        "Hallsteinn",
        "Hrolfr",
        "Helga",
        "Mundr",
        "Halfdan",
        "Gulbrandr",
        "Hrafn",
        "Torleif",
        "Gunnbjorg",
        "Gyda",
        "Donar",
        "Gustaaf",
        "Sigmundr",
        "Tallak",
        "Elvar",
        "Bjornar",
        "Ritta",
        "Ketil",
        "Hughard",
        "Knut",
        "Alvis",
        "Trym",
        "Lauge",
        "Oleg",
        "Gytha",
        "Ingemar",
        "Dasa",
        "Elli",
        "Gunnvor",
        "Kasen",
        "Volha",
        "Sigrsteinn",
        "Alfvin",
        "Bui",
        "Gudfridr",
        "Reidar",
        "Njord",
        "Oskari",
        "Sindri",
        "Gudbrand",
        "Pirjo",
        "Bjartr",
        "Asketill",
        "Ranulf",
        "Kori",
        "Egill",
        "Sundri",
        "Terje",
        "Hroarr",
        "Ingulfr",
        "Gustave",
        "Hroderich",
        "Tivari",
        "Valdis",
        "Gudridur",
        "Gunvald",
        "Bergljot",
        "Kare",
        "Guusje",
        "Kirkja",
        "Svein",
        "Alfarr",
        "Knud",
        "Arnfinn",
        "Tova",
        "Noll",
        "Inkeri",
        "Runar",
        "Jalmari",
        "Randi",
        "Torgeir",
        "Torgny",
        "Osku",
        "Oddbjorn",
        "Selby",
        "Birgit",
        "Hlif",
        "Torsten",
        "Idony",
        "Reginfridr",
        "Dagmara",
        "Runi",
        "Guus",
        "Ragnar",
        "Radulfr",
        "Osten",
        "Gustav",
        "Oszkar",
        "Somhairle",
        "Sondre",
        "Erland",
        "Unnr",
        "Toril",
        "Stian",
        "Asdis",
        "Andor",
        "Algautr",
        "Ralf",
        "Audr",
        "Eysteinn",
        "Tyr",
        "Eerikki",
        "Arild",
        "Tomhar",
        "Erna",
        "Gustav",
        "Jari",
        "Roald",
        "Alfhild",
        "Yngvi",
        "Fridjofr",
        "Holger",
        "Bothildr",
        "Vidart",
        "Astrid",
        "Jordis",
        "Tollak",
        "Cason",
        "Randolf",
        "Kustaa",
        "Sigurd",
        "Randall",
        "Ragnfridr",
        "Ragna",
        "Asvaldr",
        "Tormod",
        "Einarr",
        "Erich",
        "Ylva",
        "Brennan",
        "Gunars",
        "Agnarr",
        "Sverre",
        "Vetlidi",
        "Gudleifr",
        "Gaute",
        "Halldora",
        "Somerled",
        "Eero",
        "Rayner",
        "Dagr",
        "Hallvard",
        "Gleb",
        "Olivia",
        "Oydis",
        "Gardr",
        "Keir",
        "Svana",
        "Ivar",
        "Balder",
        "Rune",
        "Heming",
        "Aile",
        "Jarl",
        "Gunther",
        "Woden",
        "Pirkko",
        "Ingvildr",
        "Dagny",
        "Gandralfr",
        "Trond",
        "Adalbjorg",
        "Oden",
        "Grimhildr",
        "Idonea",
        "Oliwier",
        "Groa",
        "Hella",
        "Kriemhild",
        "Evander",
        "Soini",
        "Amund",
        "Svanhildr",
        "Dagnija",
        "Ebbe",
        "Oskar",
        "Arne",
        "Olaug",
        "Torbjorn",
        "Kjellfrid",
        "Inka",
        "Oystein",
        "Jorunnr",
        "Gudleif",
        "Frej",
        "Piritta",
        "Halle",
        "Solvej",
        "Runa",
        "Saga",
        "Brand",
        "Katla",
        "Vigdis",
        "Frida",
        "Brant",
        "Fritjof",
        "Elwin",
        "Steingrimr",
        "Dagfinnr",
        "Bodil",
        "Magnus",
        "Tygo",
        "Bjorn",
        "Ingegerd",
        "Harald",
        "Raghnaid",
        "Tofi",
        "Vegard",
        "Asta",
        "Gerd",
        "Gautstafr",
        "Torvald",
        "Olaf",
        "Amhlaidh",
        "Osmond",
        "Gunnr",
        "Eyvindr",
        "Saldis",
        "Igor",
        "Danr",
        "Tonje",
        "Gote",
        "Kjeld",
        "Jorsteinn",
        "Torkel",
        "Eira",
        "Baggi",
        "Iver",
        "Ragnbjorg",
        "Asa",
        "Wotan",
        "Eindride",
        "Eberhard",
        "Gudmundr",
        "Ragnhild",
        "Osborn",
        "Kalfr",
        "Grid",
        "Asmund",
        "Reidun",
        "Helka",
        "Jerrik",
        "Vigi",
        "Steen",
        "Sif",
        "Ruare",
        "Ase",
        "Hama",
        "Ragnvald",
        "Knutr",
        "Oyvind",
      ],
    },
    {
      source: "Ironsworn",
      theme: "Names",
      title: "Elf Names",
      core: true,
      prompts: [
        "Arsula",
        "Naidita",
        "Belesunna",
        "Vidarna",
        "Ninsunu",
        "Balathu",
        "Dorosi",
        "Gezera",
        "Zursan",
        "Seleeku",
        "Utamara",
        "Nebakay",
        "Dismashk",
        "Mitunu",
        "Atani",
        "Kinzura",
        "Sumula",
        "Ukames",
        "Ahmeshki",
        "Ilsit",
        "Mayatanay",
        "Etana",
        "Gamanna",
        "Nessana",
        "Uralar",
        "Tishetu",
        "Leucia",
        "Sutahe",
        "Dotani",
        "Uktannu",
        "Retenay",
        "Kendalanu",
        "Tahuta",
        "Mattissa",
        "Anatu",
        "Aralu",
        "Arakhi",
        "Ibrahem",
        "Sinosu",
        "Jemshida",
        "Visapni",
        "Hullata",
        "Sidura",
        "Kerihu",
        "Ereshki",
        "Cybela",
        "Anunna",
        "Otani",
        "Ditani",
        "Faraza",
        "Uktosi",
        "Kendeku",
        "Ukamuta",
        "Jemshalanu",
        "Videla",
        "Ditera",
        "Keralanu",
        "Jemshes",
        "Ilsashk",
        "Kenduri",
        "Uktunna",
        "Gamarna",
        "Seleuri",
        "Uktakay",
        "Kinzani",
        "Zursar",
        "Erannu",
        "Uralu",
        "Uketu",
        "Tishula",
        "Eria",
        "Otita",
        "Naidana",
        "Ibralu",
        "Nebata",
        "Uralapni",
        "Ahmata",
        "Cybanna",
        "Inula",
        "Erunu",
        "Ahmanna",
        "Ukalanu",
        "Sumucia",
        "Etalu",
        "Uralissa",
        "Kereshki",
        "Ibrosu",
        "Kendanna",
        "Mitani",
        "Visela",
        "Diteku",
        "Viduta",
        "Sutihu",
        "Ahmani",
        "Arsano",
        "Farunu",
        "Belesapni",
        "Ninsahem",
        "Arseshki",
        "Vidosu",
        "Nessuri",
        "Dismida",
        "Retani",
        "Gezarna",
        "Anula",
        "Uktashk",
        "Mattashk",
        "Anar",
        "Etita",
        "Erara",
        "Nebathu",
        "Nebannu",
        "Utamalar",
        "Hullit",
        "Kinzannu",
        "Zursano",
        "Gamara",
        "Kendura",
        "Etusa",
        "Erosi",
        "Ibranna",
        "Seleunu",
        "Uktela",
        "Kendahe",
        "Eriza",
        "Nebapni",
        "Visusa",
        "Arata",
        "Naidihu",
        "Retata",
        "Zursenay",
        "Arsana",
        "Kerela",
        "Vidunu",
        "Ahmannu",
        "Ninses",
        "Ilsahe",
        "Zurseku",
        "Lematu",
        "Balesu",
        "Tahan",
        "Keres",
        "Mayatahem",
        "Sumosi",
        "Retit",
        "Utamannu",
        "Dismanay",
        "Kereku",
        "Ukissa",
        "Kinzenay",
      ],
    },
    {
      source: "Ironsworn",
      theme: "Names",
      title: "Giant Names",
      core: true,
      prompts: [
        "Chony",
        "Banda",
        "Jochu",
        "Kira",
        "Khatir",
        "Chaidu",
        "Atan",
        "Buandu",
        "Javyn",
        "Khashin",
        "Bayara",
        "Temura",
        "Kidha",
        "Kathos",
        "Tanua",
        "Bashtu",
        "Jaran",
        "Othos",
        "Khutan",
        "Otaan",
        "Martu",
        "Baku",
        "Tuban",
        "Qudan",
        "Denua",
        "Khutidu",
        "Javom",
        "Chonadu",
        "Temy",
        "Kathir",
        "Khuton",
        "Jarun",
        "Chonan",
        "Kirochu",
        "Othochu",
        "Temochu",
        "Othon",
        "Chonu",
        "Bandun",
        "Tubandu",
        "Bakura",
        "Khashun",
        "Ottidu",
        "Jaros",
        "Atir",
        "Dena",
        "Kidhin",
        "Chonon",
        "Martun",
        "Buando",
        "Javua",
        "Denara",
        "Jochua",
        "Temida",
        "Bakira",
        "Bayom",
        "Jarira",
        "Tubos",
        "Atua ",
        "Chonoch",
        "Kathom",
        "Tubura",
        "Khutin",
        "Teman",
        "Bakas",
        "Bakin",
        "Buu",
        "Kathua",
        "Bashir",
        "Bashara",
        "Javida",
        "Khashan",
        "Bayandu",
        "Bayo",
        "Tubtu",
      ],
    },
    {
      source: "Ironsworn",
      theme: "Names",
      title: "Varou Names",
      core: true,
      prompts: [
        "Vata",
        "Zora",
        "Jasna",
        "Charna",
        "Tana",
        "Soveen",
        "Radka",
        "Zlata",
        "Leesla",
        "Byna",
        "Meeka",
        "Iskra",
        "Jarek",
        "Darva",
        "Neda",
        "Keha",
        "Zhivka",
        "Kvata",
        "Staysa",
        "Evka",
        "Vuksha",
        "Muko",
        "Dreko",
        "Aleko",
        "Vojan",
        "Sovjan",
        "Vatke",
        "Tajan",
        "Leeske",
        "Sovata",
        "Zorva",
        "Zilta",
        "Alerek",
        "Radro",
        "Isva",
        "Evra",
        "Sovta",
        "Meelak",
        "Charrak",
        "Kehvo",
        "Muro",
        "Radlak",
        "Vatla",
        "Zorjo",
        "Zorha",
        "Drejan",
        "Isek",
        "Drero",
        "Mutta",
        "Taeen",
        "Dreka",
        "Jasvo ",
        "Vojta",
        "Isna",
        "Vuvo ",
        "Taha",
        "Keheen",
        "Leesata",
        "Radjan",
        "Kvaka",
        "Darha",
        "Dreva",
        "Darvo",
        "Vueen",
        "Vukro",
        "Nedek",
        "Meea",
        "Zhivrak",
        "Vukke",
        "Zhiveen",
        "Dresha",
        "Zorata",
        "Zokra",
        "Nedke",
        "Vuta",
      ],
    },
    {
      source: "Ironsworn",
      theme: "Names",
      title: "Troll Names",
      core: true,
      prompts: [
        "Rattle",
        "Scratch",
        "Wallow",
        "Groak",
        "Gimble",
        "Scar",
        "Cratch",
        "Creech",
        "Shush",
        "Glush",
        "Slar",
        "Gnash",
        "Stoad",
        "Grig",
        "Bleat",
        "Chortle",
        "Cluck",
        "Slith",
        "Mongo",
        "Creak",
        "Burble",
        "Vrusk",
        "Snuffle",
        "Leech",
        "Herk",
        "Snarl",
        "Stomp",
        "Gurgle",
        "Bash",
        "Sniffle",
        "Crush",
        "Wuffle",
        "Snaggle",
        "Mudgo",
        "Burp",
        "Stamp",
        "Foots",
        "Wart",
        "Guts",
        "Gnarl",
        "Smash",
        "Bump",
        "Thump",
        "Wump",
        "Snigger",
        "Gas",
        "Lash",
        "Spike",
        "Pointy",
        "Sharp",
        "Blig",
        "Blongo",
        "Screrk",
        "Blarg",
        "Scarg",
        "Scroak",
        "Vrig",
        "Vrar",
        "Vrugle",
        "Stig",
        "Scrak",
        "Hatch",
        "Slerk",
        "Slatch",
        "Grak",
        "Crongo",
        "Groongo",
        "Moad",
        "Slurgle",
        "Scroad",
        "Charl",
        "Sturgle",
        "Scrongo",
        "Stutch",
        "Charak",
      ],
    },
    {
      source: "Ironsworn",
      theme: "Character",
      title: "Races",
      core: true,
      prompts: ["Ironlander", "Elf", "Giant", "Varou", "Troll"],
    },
    {
      source: "Ironsworn",
      theme: "Plot",
      title: "Combat Actions",
      core: true,
      prompts: [
        "Compel a surrender.",
        "Coordinate with allies.",
        "Gather reinforcements.",
        "Seize something or someone.",
        "Provoke a reckless response.",
        "Intimidate or frighten.",
        "Reveal a surprising truth.",
        "Shift focus to someone or something else.",
        "Destroy something, or render it useless.",
        "Take a decisive action.",
        "Reinforce defenses.",
        "Ready an action.",
        "Use the terrain to gain advantage.",
        "Leverage the advantage of a weapon or ability.",
        "Create an opportunity.",
        "Attack with precision.",
        "Attack with power.",
        "Take a completely unexpected action.",
        "Offer a quick and clean kill.",
        "Give a choice of who or what to save. (Trolley problem)",
        "Guard and defend only.",
        "Distract from a surprise attack",
        "Fight dirty.",
        "Command others to fight in their stead.",
        "Compel to join their side.",
        "Release someone or something as a distraction.",
        "Study opponent tactics.",
        "Taunt or ridicule.",
        "Tell a convincing falsehood.",
        "Feint and strike.",
        "Reveal an item of significance.",
        "Attempt to disarm opponent.",
        "Secure an advantage.",
        "Attempt a killing blow.",
        "Attack in a wild frenzy.",
        "Offer to surrender for a price.",
        "Panic and flee.",
        "Disengage and show no further aggression.",
        "Retreat and move towards cover.",
        "Wait for opponent to make the next move.",
        "Probe opponent's defenses.",
        "Drag out the fight to wear the opponent down.",
        "Use the circumstances of the battle to gain an advantage.",
        "Use opponent's weapon against them.",
        "Press a tactical advantage.",
        "Make several attacks in quick succession.",
        "Charge boldly.",
        "Take a calculated risk.",
        "Give an evil monologue.",
        "Reveal a connection to an ally.",
        "Sacrifice someone or something else.",
        "Test an opponent's bond",
      ],
    },
    {
      source: "Ironsworn",
      theme: "Plot",
      title: "Mystic Backlash",
      core: true,
      prompts: [
        "Your ritual has the opposite affect.",
        "You are sapped of strength.",
        "Your friend, ally, or companion is adversely affected.",
        "You destroy an important object.",
        "You inadvertently summon a horror.",
        "You collapse, and drift into a troubled sleep.",
        "You undergo a physical torment which leaves its mark upon you.",
        "You hear ghostly voices whispering of dark portents.",
        "You are lost in shadow, and find yourself in another place without memory of how you got there.",
        "You alert someone or something to your presence.",
        "You are not yourself, and act against a friend, ally, or companion.",
        "You affect or damage your surroundings, causing a disturbance or potential harm.",
        "You waste resources.",
        "You suffer the loss of a sense for several hours.",
        "You lose your connection to magic for a day or so, and cannot perform rituals.",
        "Your ritual affects the target in an unexpected and problematic way.",
        "Your ritual reveals a surprising and troubling truth.",
        "You are tempted by dark powers.",
        "You see a troubling vision of your future.",
        "You can't perform this ritual again until you acquire an important component.",
        "You develop a strange fear or compulsion.",
        "Your ritual causes creatures to exhibit strange or aggressive behavior.",
        "You are tormented by an apparition from your past.",
        "You are wracked with sudden sickness.",
        "Your ritual affects the wrong target.",
        "You become obsessed with performing more and more powerful rituals for a time.",
        "A nearby innocent is adversely affected.",
        "An important object is damaged.",
        "You inadvertently create a horror near a place or person you share a bond with.",
        "Your spirit leaves your body and wanders for a time.",
        "You undergo a mental torment which leaves its mark upon you.",
        "You have a vision of two possible futures: one dark and the other darker still.",
        "Your ritual binds you to the target in an unexpected and problematic way.",
        "You draw out a nearby beast.",
        "You inadvertently cause a friend, ally, or companion to act against you.",
        "Your ritual causes a friend, ally, or companion to exhibit strange or aggressive behavior.",
        "You waste time.",
        "You are overwhelmed by despair.",
        "You lose your sense of discernment and become impulsive for several hours.",
        "You collapse and awake days later.",
        "You are tempted by greed or lust.",
        "Your ritual reveals a previously held truth to actually be false.",
        "You see a troubling vision of a future for a friend, ally, or companion.",
        "You may only perform this ritual and no other for a time.",
        "You develop a strange obsession or fascination",
        "You affect your surroundings causing rapid growth of dangerous plants or fungus.",
        "You are sent into a state of madness.",
        "A friend, ally, or companion is tormented by an apparition from their past.",
        "Chaos touches your ritual so that it has the effect of a different random ritual.",
        "This place becomes a site of power that continually draws roaming horrors to it.",
        "A bond is tested as a result of this ritual.",
        "An important object is lost in shadow and reappears in another place.",
        "You are overwhelmed by fear.",
        "You undergo an emotional torment which leaves its mark upon you.",
        "You are filled seemingly boundless energy that compels you to run aimlessly and recklessly.",
        "You are inhabited by a powerful presence and proclaim a prophecy in an otherworldly voice.",
        "You are transformed into an animal and roam the lands for a time giving way to your primitive impulses",
        "Knowledge of the ritual you performed gives an advantage to an adversary.",
        "Your senses are amplified for several hours and overwhelm you at times.",
        "Your ritual causes a friend, ally, or companion to behave passively.",
        "Your ritual affects the target hours later.",
        "Your ritual reveals a change in the nature of an iron vow you have sworn.",
        "You are covered with boils and sores.",
        "You see a troubling vision involving a settlement you share a bond with.",
        "For the next significant amount of time, you can only perform this ritual at the same time of day you are performing it now.",
        "Your sense of direction is muddled for a time causing you to add +1 to all challenge dice when making an adventure move.",
        "Your connection to magic weakens for a day or so causing you to add +1 to all challenge dice when performing a ritual.",
        "A memory of past failure resurfaces powerfully wracking you with guilt and causing you to hesitate at critical moments.",
        "Dark whispers tempt you to forsake a vow.",
        "You have lethargy and dim wits for a time.",
        "You inadvertently place a curse on the area. Ask the Oracle about the nature of it.",
        "You waste good will.",
        "Roll twice more on this table. Both results occur. If they are the same result, make it worse.",
      ],
    },
    {
      source: "Ironsworn",
      theme: "Plot",
      title: "Major Plot Twist",
      core: true,
      prompts: [
        "It was all a diversion.",
        "A dark secret is revealed.",
        "A trap is sprung.",
        "An assumption is revealed to be false.",
        "A secret alliance is revealed.",
        "Your actions benefit an enemy.",
        "Someone returns unexpectedly.",
        "A more dangerous foe is revealed.",
        "You and an enemy share a common goal.",
        "A true identity is revealed.",
        "You are betrayed by someone who was trusted.",
        "You are too late.",
        "The true enemy is revealed.",
        "The enemy gains new allies.",
        "A new danger appears.",
        "Someone or something goes missing.",
        "The truth of a relationship is revealed.",
        "Two seemingly unrelated situations are shown to be connected.",
        "Unexpected powers or abilities are revealed.",
        "Bad news arrives from afar.",
        "A fracture forms in a previously solid alliance.",
        "An old foe you thought defeated returns.",
        "The wrong person shows up at the wrong time.",
        "A friend, ally, or companion abandons the quest.",
        "An important item is broken or destroyed.",
        "A reliable ability is suddenly unreliable.",
        "You are tempted by a bribe to abandon your vow.",
        "The enemy exploits your weakness.",
        "An odd symbol begins to show up in multiple places. What does it mean?",
        "A friend, ally, or companion is captured.",
        "The weather or terrain becomes a hindrance.",
        "A past action comes back to haunt you.",
        "You are faced with a choice of the lesser of two evils.",
        "A friend, ally, or companion suddenly becomes hesitant.",
        "A trusted authority is shown to be corrupt.",
        "A friend, ally, or companion is cursed.",
        "It is revealed that the enemy has gathered new information about your plans.",
        "Critical information is lost or withheld.",
        "You are accused of a crime and being hunted",
        "An unexpected guardian impedes your progress until appeased, circumvented, or defeated.",
        "Innocent lives are now in danger due to your actions.",
        "Rumors spread which ruin your reputation.",
        "A tactical advantage is lost.",
        "Something you thought false is revealed to be true.",
        "You are separated from an ally, friend, or companion in a troubling way.",
        "A trap or circumstance of the environment forces you to work with the enemy to survive.",
        "An action is much more effective than you anticipated which complicates the situation.",
        "You discover the next destination on your journey is considered off limits by local custom and travel there could cause friction.",
        "You draw attention to yourself in a troubling way.",
        "An action takes much more time than anticipated causing you meet someone or something best avoided.",
        "An ally, friend, or companion is unexpectedly injured.",
        "You are made to look the fool.",
        "You lose significant support for your cause.",
        "Something you wanted to remain hidden is revealed.",
        "Your actions were witnessed and interpreted in a detrimental way.",
        "A new ally appears and forcefully takes charge of the situation doing more harm than good.",
        "A friend, ally, or companion is accused of a crime and being hunted.",
        "Roll twice more on this table. Both results occur. If they are the same result, make it more dramatic.",
      ],
    },
    {
      source: "Ironsworn",
      theme: "Plot",
      title: "Challenge Rank",
      core: true,
      prompts: ["Troublesome", "Dangerous", "Formidable", "Extreme", "Epic"],
    },
    {
      source: "Delve",
      theme: "Site",
      title: "Delve Theme",
      core: true,
      prompts: ["Ancient", "Corrupted", "Fortified", "Hallowed", "Haunted", "Infested", "Ravaged", "Wild"],
    },
    {
      source: "Delve",
      theme: "Site",
      title: "Delve Domain",
      core: true,
      prompts: [
        "Barrow",
        "Cavern",
        "Frozen Cavern",
        "Ice Reach",
        "Mine",
        "Pass",
        "Ruin",
        "Sea Cave",
        "Shadowfen",
        "Stronghold",
        "Tanglewood",
        "Underkeep",
      ],
    },
    {
      source: "Delve",
      theme: "Feature",
      title: "Aspect",
      core: true,
      prompts: [
        "Blocked",
        "Crafted",
        "Ancient",
        "Sunken",
        "Trapped",
        "Secret",
        "Toxic",
        "Ruined",
        "Defended",
        "Decaying",
        "Marked",
        "Guarded",
        "Inaccessible",
        "Foreboding",
        "Veiled",
        "Deep",
        "Depleted",
        "Foul",
        "Elevated",
        "Moving",
        "Unnatural",
        "Active",
        "Confined",
        "Fortified",
        "Collapsed",
        "Isolated",
        "Destroyed",
        "Open",
        "Sacred",
        "Flooded",
        "Complex",
        "Abundant",
        "Hidden",
        "Expansive",
        "Mysterious",
        "Unstable",
        "Fragile",
        "Broken",
        "Ensnaring",
        "Pillaged",
        "Sealed",
        "Makeshift",
        "Treacherous",
        "Natural",
        "Dead",
        "Unusual",
        "Abandoned",
        "Deadly",
        "Forgotten",
        "Mystical",
      ],
    },
    {
      source: "Delve",
      theme: "Feature",
      title: "Focus",
      core: true,
      prompts: [
        "Attack",
        "Threshold",
        "Boundary",
        "Alarm",
        "Exit",
        "Passage",
        "Crossing",
        "Trigger",
        "Trap",
        "Hideaway",
        "Nature",
        "Sign",
        "Refuge",
        "Valuables",
        "Breach",
        "Route",
        "Location",
        "Trail",
        "Supply",
        "History",
        "Prisoner",
        "Habitation",
        "Debris",
        "Creature",
        "Lair",
        "Person",
        "Enclosure",
        "Remains",
        "Water",
        "Message",
        "Darkness",
        "Opening",
        "Weapon",
        "Entry",
        "Illumination",
        "Obstacle",
        "Craft",
        "Container",
        "Information",
        "Grave",
        "Equipment",
        "Shelter",
        "Denizen",
        "Environment",
        "Material",
        "Resource",
        "Corruption",
        "Death",
        "Function",
        "Power",
      ],
    },
    {
      source: "Delve",
      theme: "Site",
      title: "Delve Denizen Tags",
      core: true,
      prompts: [
        "arctic",
        "marine",
        "pass",
        "shadowfen",
        "subterranean",
        "tanglewood",
        "vermin",
        "corrupted",
        "faithful",
        "wild",
        "remnant",
        "sentient",
        "squatter",
        "undead",
        "immobile",
        "flying",
        "plains",
        "wastes",
      ],
    },
    {
      source: "Delve",
      theme: "Site",
      title: "Delve Descriptor",
      core: true,
      prompts: [
        "deep",
        "tainted",
        "gray",
        "forgotten",
        "flooded",
        "forbidden",
        "barren",
        "lost",
        "cursed",
        "fell",
        "sunken",
        "nightmare",
        "infernal",
        "dark",
        "bloodstained",
        "haunted",
        "white",
        "shrouded",
        "wasted",
        "grim",
        "endless",
        "crumbling",
        "undying",
        "bloodied",
        "forsaken",
        "silent",
        "blighted",
        "iron",
        "frozen",
        "abyssal",
        "crimson",
        "silver",
        "desecrated",
        "ashen",
        "elder",
        "scorched",
        "unknown",
        "scarred",
        "broken",
        "chaotic",
        "black",
        "hidden",
        "sundered",
        "shattered",
        "dreaded",
        "secret",
        "high",
        "sacred",
        "fallen",
        "ruined",
      ],
    },
    {
      source: "Delve",
      theme: "Site",
      title: "Delve Detail",
      core: true,
      prompts: [
        "blight",
        "strife",
        "nightfall",
        "fury",
        "terror",
        "truth",
        "spring",
        "sanctuary",
        "bone",
        "specters",
        "daybreak",
        "doom",
        "treachery",
        "blood",
        "war",
        "torment",
        "iron",
        "silence",
        "mist",
        "isolation",
        "runes",
        "rot",
        "corruption",
        "prophecy",
        "fate",
        "twilight",
        "power",
        "darkness",
        "gloom",
        "storms",
        "hope",
        "lament",
        "frost",
        "souls",
        "winter",
        "sadness",
        "desolation",
        "bane",
        "lies",
        "ash",
        "banishment",
        "shadow",
        "madness",
        "stone",
        "secrets",
        "despair",
        "blades",
        "dread",
        "light",
        "wrath",
      ],
    },
    {
      source: "Delve",
      theme: "Plot",
      title: "Delve Opportunity",
      core: true,
      prompts: [
        "The terrain favors you, or you find a hidden path.",
        "An aspect of the history or nature of this place is revealed.",
        "You locate a secure area.",
        "A clue offers insight or direction.",
        "You get the drop on a denizen.",
        "This area provides an opportunity to scavenge, forage, or hunt.",
        "You locate an interesting or helpful object.",
        "You are alerted to a potential threat.",
        "You encounter a denizen who might support you.",
        "You encounter a denizen in need of help.",
      ],
    },
    {
      source: "Delve",
      theme: "Place",
      title: "Delve Place, Barrow Type",
      core: true,
      prompts: ["sepulcher", "grave", "crypt", "mound", "tomb", "barrow"],
    },
    {
      source: "Delve",
      theme: "Place",
      title: "Delve Place, Cavern Type",
      core: true,
      prompts: [
        "abyss",
        "hollow",
        "caverns",
        "lair",
        "caves",
        "rift",
        "chasm",
        "tunnels",
        "depths",
        "warren",
      ],
    },
    {
      source: "Delve",
      theme: "Place",
      title: "Delve Place, Frozen Cavern Type",
      core: true,
      prompts: [
        "abyss",
        "hollow",
        "caverns",
        "lair",
        "caves",
        "rift",
        "chasm",
        "tunnels",
        "depths",
        "warren",
      ],
    },
    {
      source: "Delve",
      theme: "Place",
      title: "Delve Place, Ice Reach Type",
      core: true,
      prompts: [
        "icemark",
        "waste",
        "wintertide",
        "expanse",
        "reach",
        "barrens",
      ],
    },
    {
      source: "Delve",
      theme: "Place",
      title: "Delve Place, Mine Type",
      core: true,
      prompts: ["lode", "dig", "forge", "mine", "tunnels", "cut"],
    },
    {
      source: "Delve",
      theme: "Place",
      title: "Delve Place, Pass Type",
      core: true,
      prompts: [
        "cliffs",
        "heights",
        "crag",
        "highlands",
        "cut",
        "pass",
        "gap",
        "reach",
        "gorge",
        "ridge",
      ],
    },
    {
      source: "Delve",
      theme: "Place",
      title: "Delve Place, Ruin Type",
      core: true,
      prompts: [
        "citadel",
        "sanctuary",
        "enclave",
        "sanctum",
        "fortress",
        "spire",
        "hall",
        "temple",
        "keep",
        "tower",
      ],
    },
    {
      source: "Delve",
      theme: "Place",
      title: "Delve Place, Sea Cave Type",
      core: true,
      prompts: ["caves", "hollow", "channel", "pools", "cove", "gouge"],
    },
    {
      source: "Delve",
      theme: "Place",
      title: "Delve Place, Shadowfen Type",
      core: true,
      prompts: [
        "bog",
        "morass",
        "fen",
        "quagmire",
        "lowland",
        "floodlands",
        "marsh",
        "slough",
        "mire",
        "wetlands",
      ],
    },
    {
      source: "Delve",
      theme: "Place",
      title: "Delve Place, Stronghold Type",
      core: true,
      prompts: [
        "bastion",
        "keep",
        "citadel",
        "outpost",
        "fortress",
        "refuge",
        "garrison",
        "sanctuary",
        "haven",
        "watch",
      ],
    },
    {
      source: "Delve",
      theme: "Place",
      title: "Delve Place, Tanglewood Type",
      core: true,
      prompts: [
        "weald",
        "thicket",
        "tangle",
        "forest",
        "bramble",
        "wilds",
        "briar",
        "wood",
      ],
    },
    {
      source: "Delve",
      theme: "Place",
      title: "Delve Place, Underkeep Type",
      core: true,
      prompts: [
        "catacomb",
        "maze",
        "chambers",
        "pit",
        "den",
        "sanctum",
        "hall",
        "underkeep",
        "labyrinth",
        "vault",
      ],
    },
    {
      source: "Delve",
      theme: "Monstrosity",
      title: "Size",
      core: true,
      prompts: [
        "Tiny (rodent-sized)",
        "Small (hound-sized)",
        "Medium (person-sized)",
        "Large (giant-sized)",
        "Huge (whale-sized)",
        "Titanic (incomprehensible)",
      ],
    },
    {
      source: "Delve",
      theme: "Monstrosity",
      title: "Primary Form",
      core: true,
      prompts: [
        "Beast / mammal",
        "Humanoid",
        "Bird",
        "Spider",
        "Snake",
        "Worm / slug",
        "Lizard",
        "Insect",
        "Amorphous",
        "Crustacean",
        "Fish",
        "Octopoid",
        "Amphibian",
        "Plant",
        "Incorporeal",
        "Mineral",
        "Elemental",
        "Hybrid (roll twice)",
      ],
    },
    {
      source: "Delve",
      theme: "Monstrosity",
      title: "Characteristics",
      core: true,
      prompts: [
        "Extra limbs",
        "Fangs / rows of sharp teeth",
        "Claws / talons",
        "Strange color / markings",
        "Horns / tusks",
        "Oversized mouth",
        "Spikes / spines",
        "Tail",
        "Multi-segmented body",
        "Wings",
        "Stinger / barbs",
        "Many-eyed",
        "Distinctive sound",
        "Tentacles / tendrils",
        "Mandibles / pincers",
        "Luminescent",
        "Antennae / sensory organs",
        "Proboscis / inner jaw",
        "Exoskeleton / shell",
        "Bony protuberances",
        "Corrupted flesh",
        "Semi-transparent",
        "Scarred / injured",
        "Egg sac / carried offspring",
        "Rotting / skeletal",
        "Mummified / desiccated",
        "Multi-headed",
        "Etched with mystic runes",
      ],
    },
    {
      source: "Delve",
      theme: "Monstrosity",
      title: "Abilities",
      core: true,
      prompts: [
        "Keen senses",
        "Intimidating vocalization",
        "Climber",
        "Intelligent",
        "Swift",
        "Powerful bite",
        "Stealthy / ambusher",
        "Horrid visage",
        "Strong",
        "Camouflaged",
        "Flier / glider",
        "Poisonous",
        "Semiaquatic / swimmer",
        "Grappler / entangler",
        "Leaper",
        "Crusher / constrictor",
        "Armored",
        "Burrower",
        "Noxious smell",
        "Trap-setter",
        "Parasitic",
        "Vibration sense",
        "Breath weapon / toxic spew",
        "Mimicry",
        "Shapeshifting",
        "Control lesser creatures",
        "Echolocation",
        "Electric shock",
        "Acidic",
        "Symbiotic",
        "Shoot projectiles",
        "Paralyzing",
        "Immune to iron",
        "Feels no pain",
        "Enact rituals",
        "Create illusions",
        "Mind control / telepathy",
        "Move between realities",
        "Wield weapons",
        "Control elements",
      ],
    },
    {
      source: "Delve",
      theme: "Trap",
      title: "Event",
      core: true,
      prompts: [
        "Block",
        "Create",
        "Break",
        "Puncture",
        "Entangle",
        "Enclose",
        "Ambush",
        "Snare",
        "Change",
        "Imitate",
        "Crush",
        "Drop",
        "Conceal",
        "Lure",
        "Release",
        "Obscure",
        "Cut",
        "Smother",
        "Collapse",
        "Summon",
        "Move",
        "Surprise",
        "Divert",
        "Attack",
        "Trigger",
      ],
    },
    {
      source: "Delve",
      theme: "Trap",
      title: "Component",
      core: true,
      prompts: [
        "Pit",
        "Water",
        "Fire",
        "Projectile",
        "Passage",
        "Fall",
        "Debris",
        "Fear",
        "Alarm",
        "Trigger",
        "Cold",
        "Weapon",
        "Darkness",
        "Decay",
        "Path",
        "Stone",
        "Terrain",
        "Poison",
        "Barrier",
        "Overhead",
        "Magic",
        "Toxin",
        "Earth",
        "Light",
        "Denizen",
      ],
    },
    {
      source: "Delve",
      theme: "Combat Event",
      title: "Method",
      core: true,
      prompts: [
        "Defy",
        "Break",
        "Trick",
        "Evade",
        "Protect",
        "Overwhelm",
        "Persevere",
        "Assist",
        "Await",
        "Abort",
        "Block",
        "Collide",
        "Focus",
        "Advance",
        "Breach",
        "Endure",
        "Assault",
        "Charge",
        "Escalate",
        "Sunder",
        "Shatter",
        "Aim",
        "Stagger",
        "Counter",
        "Seize",
        "Impact",
        "Entangle",
        "Hold",
        "Deflect",
        "Drop",
        "Lose",
        "Sweep",
        "Secure",
        "Cover",
        "Withdraw",
        "Clash",
        "Amplify",
        "Batter",
        "Feint",
        "Shove",
        "Embed",
        "Affect",
        "Probe",
        "Force",
        "Intensify",
        "Distract",
        "Challenge",
        "Brawl",
        "Coordinate",
        "Overrun",
      ],
    },
    {
      source: "Delve",
      theme: "Combat Event",
      title: "Target",
      core: true,
      prompts: [
        "Control",
        "Defense",
        "Limbs",
        "Focus",
        "Advantage",
        "Range",
        "Stress",
        "Sense",
        "Weakness",
        "Opening",
        "Fear",
        "Instinct",
        "Footing",
        "Maneuver",
        "Reach",
        "Harm",
        "Finesse",
        "Weapon",
        "Environment",
        "Technique",
        "Surprise",
        "Pride",
        "Wound",
        "Precision",
        "Ally",
        "Ground",
        "Courage",
        "Companion",
        "Object",
        "Momentum",
        "Speed",
        "Strength",
        "Supply",
        "Terrain",
        "Armor",
        "Skill",
        "Body",
        "Protection",
        "Resolve",
        "Ferocity",
        "Shield",
        "Ammo",
        "Anger",
        "Opportunity",
        "Balance",
        "Position",
        "Barrier",
        "Strategy",
        "Grasp",
        "Power",
      ],
    },
    {
      source: "Delve",
      theme: "Threat",
      title: "Category",
      core: true,
      prompts: [
        "Burgeoning Conflict",
        "Cursed Site",
        "Environmental Calamity",
        "Malignant Plague",
        "Rampaging Creature",
        "Ravaging Horde",
        "Scheming Leader",
        "Power-Hungry Mystic",
        "Zealous Cult",
        "Roll Twice",
      ],
    },
    {
      source: "Delve",
      theme: "Threat",
      title: "Burgeoning Conflict",
      core: true,
      prompts: [
        "Allow warmongers to gain influence",
        "Break a treaty",
        "Force a hasty decision",
        "Deepen suspicions",
        "Trigger a confrontation",
        "Subvert a potential accord",
        "Isolate the antagonists",
        "Draw new battle lines",
        "Reveal an unexpected aspect of the dispute",
        "Introduce a new person or faction to complicate the situation",
      ],
    },
    {
      source: "Delve",
      theme: "Threat",
      title: "Cursed Site",
      core: true,
      prompts: [
        "Unleash a creature or being",
        "Lure the unwary into its depths",
        "Offer promises of power",
        "Reveal a new aspect of its cursed history",
        "Expand its malignancy to surrounding lands",
        "Leave its mark on an inhabitant or visitor",
        "Reveal hidden depths",
        "Ensnare an important person or object",
        "Corrupt the environment",
        "Transform its nature",
      ],
    },
    {
      source: "Delve",
      theme: "Threat",
      title: "Environmental Calamity",
      core: true,
      prompts: [
        "Devastate a place",
        "Block a path",
        "Threaten a community with imminent destruction",
        "Manifest unexpected effects",
        "Expand in scope or intensity",
        "Allow someone to take advantage",
        "Deprive of resources",
        "Isolate an important person or community",
        "Force refugees into hostile lands",
        "Disrupt natural ecosystems",
      ],
    },
    {
      source: "Delve",
      theme: "Threat",
      title: "Malignant Plague",
      core: true,
      prompts: [
        "Manifest new symptoms or effects",
        "Infect someone important",
        "Expand to new territory or communities",
        "Allow someone to take advantage",
        "Allow someone to take the blame",
        "Create panic or disorder",
        "Force a horrible decision",
        "Lure into complacency",
        "Reveal the root of the sickness",
        "Undermine a potential cure",
      ],
    },
    {
      source: "Delve",
      theme: "Threat",
      title: "Rampaging Creature",
      core: true,
      prompts: [
        "Reveal a new aspect of its nature or abilities",
        "Expand its territory",
        "Make a sudden and brutal attack",
        "Control or influence lesser creatures",
        "Create confusion or strife",
        "Leave foreboding signs",
        "Lure the unwary",
        "Imperil an event",
        "Assert control over a location",
        "Threaten resources",
      ],
    },
    {
      source: "Delve",
      theme: "Threat",
      title: "Ravaging Horde",
      core: true,
      prompts: [
        "Overrun defenses",
        "Gather resources",
        "Attack a location",
        "Expand forces",
        "Appoint or reveal a leader",
        "Send forth a champion",
        "Create a diversion",
        "Undermine an opposing force from within",
        "Cut off supplies or reinforcements",
        "Employ a new weapon",
      ],
    },
    {
      source: "Delve",
      theme: "Threat",
      title: "Scheming Leader",
      core: true,
      prompts: [
        "Defeat an enemy",
        "Form a new alliance",
        "Usurp or undermine another leader",
        "Force the loyalty of a community or important person",
        "Enact a new law or tradition",
        "Rescind an old law or tradition",
        "Reveal a true intention",
        "Unravel an existing alliance",
        "Incite conflict",
        "Use an unexpected capability or asset",
      ],
    },
    {
      source: "Delve",
      theme: "Threat",
      title: "Power-Hungry Mystic",
      core: true,
      prompts: [
        "Gain hidden knowledge",
        "Assault an enemy with magic",
        "Despoil a place through magic",
        "Forge a bond with ancient forces",
        "Create magical wards or protections",
        "Obtain a powerful artifact",
        "Tempt with power or secrets",
        "Recruit a follower or ally",
        "Sacrifice something in exchange for greater power",
        "Use magic to trick or deceive",
      ],
    },
    {
      source: "Delve",
      theme: "Threat",
      title: "Zealous Cult",
      core: true,
      prompts: [
        "Overtake a faction or community",
        "Unlock secrets to greater power",
        "Establish false credibility",
        "Appoint or reveal a leader",
        "Lure new members or establish alliances",
        "Subvert opposition through devious schemes",
        "Attack opposition directly",
        "Spread the word of its doctrine",
        "Reveal a dire prophecy",
        "Reveal its true nature or goal",
      ],
    },
    {
      theme: "Names",
      source: "ChatGPT",
      title: "Viking names",
      core: false,
      prompts: [
        "Ragnar",
        "Freya",
        "Thorin",
        "Sven",
        "Ingrid",
        "Leif",
        "Sigrid",
        "Gunnar",
        "Hilda",
        "Bjorn",
        "Gudrun",
        "Olaf",
        "Astrid",
        "Harald",
        "Helga",
        "Eirik",
        "Yngvar",
        "Ragna",
        "Njal",
        "Brynhild",
        "Kari",
        "Gisli",
        "Sigrun",
        "Halfdan",
        "Thora",
        "Ulf",
        "Hrafn",
        "Greta",
        "Fjord",
        "Alva",
        "Sigmar",
        "Asger",
        "Solveig",
        "Thord",
        "Yrsa",
        "Kjell",
        "Ranveig",
        "Eivor",
        "Harriet",
        "Knut",
      ],
    },
    {
      theme: "Story",
      source: "Starforged",
      title: "Story clue",
      core: false,
      prompts: [
        'Affirms previously understood fact or clue',
        'Connects to known rumor or scandal',
        'Connects to previously unrelated mystery or quest',
        'Connects to your own expertise or interests',
        'Contradicts previously understood fact or clue',
        'Evokes personal memory',
        'Evokes remarkable anomaly or phenomenon',
        'Evokes vision or prophecy',
        'Involves creature',
        'Involves cultural touchstone',
        'Involves enemy or rival',
        'Involves hidden or mysterious faction',
        'Involves hidden or mysterious person',
        'Involves key or means of access',
        'Involves non-human being or creature',
        'Involves notable faction',
        'Involves notable person',
        'Involves person or faction from your background',
        'Involves personal item',
        'Involves ritual or magic',
        'Involves someone you trust',
        'Involves something rare, expensive, or precious',
        'Involves unusual ability or power',
        'Leads to distant or unfamiliar place',
        'Leads to hidden or forgotten place',
        'Leads to nearby or familiar place',
        'Leads to notable or central place',
        'Suggests history of similar incidents',
        'Suggests imposter or forgery',
        'Suggests looming event or deadline',
        'Descriptor + Focus',
      ],
    },
    {
      theme: "Story",
      source: "Starforged",
      title: "Story complication",
      core: false,
      prompts: [
        'Crucial equipment or artifact fails',
        'Debt or promise comes due',
        'Enemy reveals their true agenda or nature',
        'Enemy reveals unexpected powers, abilities, or influence',
        'Enemy unexpectedly benefits from your actions',
        'Key location is made inaccessible',
        'Key location is threatened or made unsafe',
        'Magic or artifact is shown to have unexpected effects',
        'Natural disaster is imminent',
        'Needed item or resource is unavailable',
        'Object of a quest is not what you assumed',
        'Old enemy resurfaces',
        'Simultaneous problems force a hard choice',
        'Someone important betrays your trust',
        'Someone important is threatened or endangered',
        'Someone important reveals their problematic secret or history',
        'Something important goes missing',
        'Time pressure suddenly increases',
        'Trap is sprung',
        'True agenda of a connection or patron is revealed',
        'Trusted information is shown to be false',
        'Two seemingly unrelated problems show to be connected',
        'Undermined by self-doubt or vulnerabilities',
        'Unexpected enemies appear',
        'Urgent message distracts you from your quest',
        'You are tracked or followed',
        'You were diverted from the true crisis',
        'Roll twice'
      ]
    }
  ];

  diceRoller: DiceRoller;

  constructor(state: any) {
    this.diceRoller = new DiceRoller();
    if (!state) return;
    this.editOracleList = state.editOracleList;
    this.newOracleTableName = state.newOracleTableName;
    this.tables = state.tables;
    this.rollHistory = state.rollHistory ?? [];
    this.selectedOracleSource = state.selectedOracleSource;
    this.selectedOracleTheme = state.selectedOracleTheme;
    this.selectedOracleTable = state.selectedOracleTable;
  }

  titleCase(str: string) {
    str = str.toLowerCase();
    const parts = str.split(" ");
    for (var i = 0; i < parts.length; i++) {
      parts[i] = parts[i].charAt(0).toUpperCase() + parts[i].slice(1);
    }
    return parts.join(" ");
  }

  isCore(table: string) {
    let oracleTable = this.tables.find((o) => o.title === table);
    return oracleTable?.core;
  }

  getRandomPromptFromOracleTable(table: string) {
    let oracle = this.tables.find((o) => o.title === table);
    if (!oracle) return "";
    let rn = this.diceRoller.roll([oracle.prompts.length]);
    let result = oracle.prompts[rn[0].value];
    this.addRollHistory(oracle.theme, oracle.title, result);
    return result;
  }

  addRollHistory(theme: string, table: string, result: string) {
    this.rollHistory.unshift({ Theme: theme, Table: table, Result: result });
    if (this.rollHistory.length > 15)
      this.rollHistory = this.rollHistory.slice(0, 14);
  }

  getOracleTablePrompts(table: string) {
    let out = "";
    let oracleTable = this.tables.find((o) => o.title === table);
    if (!oracleTable) return;

    oracleTable.prompts.map((p) => (out += p + "\n"));
    out.replace(/^\s+|\s+$/g, "");
    return out;
  }

  getOracleTableAsArray(table: string) {
    if (table === "Select Table") return;
    let t = this.tables.find((o) => o.title === table)?.prompts;
    return t;
  }

  getDelveSiteName(domain: string) {
    let out = "";
    let rn = this.diceRoller.roll([7])[0].value;
    let descriptor = this.titleCase(
      this.getRandomPromptFromOracleTable("Delve Descriptor")
    );
    let detail = this.titleCase(
      this.getRandomPromptFromOracleTable("Delve Detail")
    );
    let place = this.titleCase(
      this.getRandomPromptFromOracleTable(`Delve Place, ${domain} Type`)
    );
    let namesake = this.getRandomPromptFromOracleTable("Ironlander Names");
    switch (rn) {
      case 0:
        out += `the ${descriptor} ${place}`;
        break;
      case 1:
        out += `the ${place} of ${descriptor}`;
        break;
      case 2:
        out += `the ${place} of ${descriptor} ${detail}`;
        break;
      case 3:
        out += `the ${place} of ${namesake}'s ${detail}`;
        break;
      case 4:
        out += `${namesake}'s ${place}`;
        break;
      case 5:
        out += `the ${descriptor} ${place} of ${namesake}`;
        break;
      case 6:
        out += `the ${place} of ${namesake}`;
        break;
    }
    return out;
  }

  getNPCName(race: string) {
    return this.getRandomPromptFromOracleTable(`${race} Names`);
  }

  get Action() {
    return this.getRandomPromptFromOracleTable("Actions");
  }

  get Theme() {
    return this.getRandomPromptFromOracleTable("Theme");
  }

  get Region() {
    return this.getRandomPromptFromOracleTable("Region");
  }

  get Location() {
    return this.getRandomPromptFromOracleTable("Location");
  }

  get CoastalWatersLocation() {
    return this.getRandomPromptFromOracleTable("Coastal Waters Location");
  }

  get LocationDescriptor() {
    return this.getRandomPromptFromOracleTable("Location Descriptor");
  }

  get LocationTrouble() {
    return this.getRandomPromptFromOracleTable("Settlement Trouble");
  }

  get SettlementName() {
    if (parseInt(this.diceRoller.roll([2])[0].value) - 1) {
      return this.getRandomPromptFromOracleTable("Settlement Names");
    } else {
      let prefix = this.getRandomPromptFromOracleTable(
        "Settlement Name Prefixes"
      );
      let suffix = this.getRandomPromptFromOracleTable(
        "Settlement Name Suffixes"
      );
      return prefix + suffix.toLowerCase();
    }
  }

  get IronlanderName() {
    return this.getRandomPromptFromOracleTable("Ironlander Names");
  }

  get CharacterRole() {
    return this.getRandomPromptFromOracleTable("Character Role");
  }

  get CharacterGoal() {
    return this.getRandomPromptFromOracleTable("Character Goal");
  }

  get CharacterDescriptor() {
    return this.getRandomPromptFromOracleTable("Character Descriptor");
  }

  get CharacterDisposition() {
    return this.getRandomPromptFromOracleTable("Character Disposition");
  }

  get NPCRace() {
    return this.getRandomPromptFromOracleTable("Races");
  }

  get NPCConversation() {
    return this.getRandomPromptFromOracleTable("NPC Conversation");
  }

  get NPCKnowledge() {
    let prefix = this.getRandomPromptFromOracleTable("NPC Knowledge Type");
    let suffix = this.getRandomPromptFromOracleTable("NPC Knowledge Topic");
    return prefix + " " + suffix;
  }

  get PrimaryStat() {
    return this.diceRoller.roll([5], true)[0].value;
  }

  get DelveOpportunity() {
    return this.getRandomPromptFromOracleTable("Delve Opportunity");
  }

  getOracleTableSources() {
    let tn: string[] = [];
    this.tables.map((o) => {
      if (!tn.includes(o.source)) tn.push(o.source);
      return undefined;
    });
    return tn;
  }

  getOracleTableThemes(source: string) {
    let tn: string[] = [];
    this.tables
      .filter((t) => t.source.match(source))
      .map((o) => {
        if (!tn.includes(o.theme)) tn.push(o.theme);
        return undefined;
      });
    return tn;
  }

  getOracleTableNames(source: string, theme: string) {
    let tn: string[] = [];
    this.tables
      .filter((t) => t.source.match(source) && t.theme.match(theme))
      .map((o) => tn.push(o.title));
    return tn;
  }

  get DelveTheme() {
    return this.getRandomPromptFromOracleTable("Delve Theme");
  }

  get DelveDomain() {
    return this.getRandomPromptFromOracleTable("Delve Domain");
  }
}

export default Oracles;
