import React, { useCallback, useLayoutEffect } from "react";
// import ReactHtmlParser from "react-html-parser";
import parse from "html-react-parser";
import ReactMarkdown from "react-markdown";
// import StatTrack from "./statTrack";
import "./AssetCard.scss";
import { Asset } from "./types";
import { useAppDispatch } from "../../app/hooks";
import {
  selectSelectedAssets,
  toggleAbility,
  toggleSelectAsset,
  updateInputField,
  updateStatTrack,
} from "../progress/progressSlice";
import { useSelector } from "react-redux";
import { StatTrack } from "./StatTrack";

function formatAbility(name: string, text: string) {
  let str = "";
  if (text.includes("</")) {
    str = name ? `<strong>${name}:</strong>&nbsp${text}` : text;
    return parse(str);
  } else {
    str = name ? `**${name}:** ${text}` : text;
    return <ReactMarkdown>{str}</ReactMarkdown>;
  }
}

const AbilityListItem = ({
  ability,
  assetId,
  assetState,
}: {
  ability: Asset["Abilities"][0];
  assetId: string;
  assetState?: ReturnType<typeof selectSelectedAssets>[0];
}) => {
  const d = useAppDispatch();
  const doToggleAbility = (e: React.ChangeEvent<HTMLInputElement>) => {
    d(toggleAbility({ assetId, abilityId: ability.Name ?? ability.Text }));
  };

  return (
    <div className="row">
      <label className="control control-checkbox">
        <input
          type="checkbox"
          checked={
            ability.Enabled ||
            assetState?.abilities?.some(
              (x) => x === (ability.Name ?? ability.Text)
            )
          }
          onChange={doToggleAbility}
        />
        <div className="control_indicator"></div>
      </label>
      <div className="col">{formatAbility(ability.Name, ability.Text)}</div>
    </div>
  );
};

const InputField = ({
  assetId,
  field,
  fieldIndex,
  disabled,
  assetState,
}: {
  assetId: string;
  field: Asset["InputFields"][0];
  fieldIndex: number;
  disabled: boolean;
  assetState?: ReturnType<typeof selectSelectedAssets>[0];
}) => {
  const d = useAppDispatch();
  const value = assetState?.inputFields?.[fieldIndex] || field.value || "";
  const doUpdateInputField = (e: React.ChangeEvent<HTMLInputElement>) => {
    d(updateInputField({ assetId, fieldIndex, value: e.target.value }));
  };
  return (
    <div className="row input-field">
      <div className="input-group">
        <div className="input-group-prepend">
          <label htmlFor={`${assetId}||input||${fieldIndex}`}>
            <strong>{field.name}:</strong>&emsp;
          </label>
        </div>
        <input
          id={`${assetId}||input||${fieldIndex}`}
          className="form-control form-control-sm"
          type="text"
          value={value}
          disabled={disabled}
          onChange={doUpdateInputField}
        />
        <div className="hr"></div>
      </div>
    </div>
  );
};

export const AssetCard = ({
  asset,
  disabled = false,
  shouldScrollIntoView = false,
}: {
  asset: Asset;
  disabled?: boolean;
  shouldScrollIntoView?: boolean;
}) => {
  const d = useAppDispatch();
  const assetState = useSelector(selectSelectedAssets).find(
    (x) => x.id === asset.id
  );
  const isSelected = !!assetState;
  const toggleSelection = () => {
    d(toggleSelectAsset(asset.id));
  };
  useLayoutEffect(() => {
    if (shouldScrollIntoView) {
      const el = document.getElementById(asset.id);
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "center" });
        // d(selectAsset(undefined));
      }
    }
  }, [asset.id, d, isSelected, shouldScrollIntoView]);

  const handleStatTrackChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, label: string) => {
    d(updateStatTrack({ assetId: asset.id, trackId: label, value: parseInt(e.target.value) }));
  }, [asset.id, d]);
  return (
    <div
      id={asset.id}
      className="card asset-card"
    >
      <div className="card-header bg-dark text-light">
        <h6>{asset.Type}</h6>
        <div
          className={`asset-icon ${isSelected ? "asset-icon--selected" : ""}`}
          onClick={toggleSelection}
          title="Augment Asset"
        >
          <i
            className={`game-icon game-icon-${asset.icon}`}
            aria-hidden="true"
          ></i>
        </div>
      </div>
      <div className="card-body">
        <h4>{asset.Name}</h4>

        {asset.InputFields?.filter(
          (f) => f.name !== undefined && f.name !== ""
        ).map((f, fieldIndex) => <InputField key={f.name} disabled={disabled} assetId={asset.id} field={f} fieldIndex={fieldIndex} assetState={assetState} />)}
        {!!asset.Description && <p>{asset.Description}</p>}

        {asset.Abilities.filter(
          (a) =>
            (a.Name !== undefined && a.Name !== "") ||
            (a.Text !== undefined && a.Text !== "")
        ).map((a) => (
          <AbilityListItem
            key={a.Name || a.Text}
            assetId={asset.id}
            ability={a}
            assetState={assetState}
          />
        ))}

        {((asset.TrackMax !== undefined && asset.TrackMax > 0) ||
            (asset.TrackLabels !== undefined &&
              asset.TrackLabels?.length > 0)) && (
            <div className="stat-track-container">
              <StatTrack
                min={0}
                max={
                  asset.TrackLabels &&
                  asset.TrackLabels.length > 0 &&
                  asset.TrackLabels[0] !== ""
                    ? asset.TrackLabels.length - 1
                    : asset.TrackMax
                }
                onChange={handleStatTrackChange}
                value={assetState?.trackValue ?? asset.TrackValue}
                label={asset.id} // {this.props.asset.id}
                hideThumb={false}
                hideLabel={true}
              />
            </div>
          )}
      </div>
    </div>
  );
};
