import React, { useMemo } from "react";
import './StatTrack.scss';

export const StatTrack = ({
  min,
  max,
  value,
  label,
  hideLabel,
  hideThumb,
  onChange,
  trackLabels,
}: {
  min: number;
  max: number;
  value: number;
  label: string;
  hideLabel?: boolean;
  hideThumb?: boolean;
  trackLabels?: string[];
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement>,
    label: string
  ) => void;
}) => {
  // const offset = min < 0 ? min * -1 : min;
  const ticks = useMemo(() => {
    let ticks = [];
    for (let i = min; i <= max; i++) {
      ticks.push(i);
    }
    return ticks;
  }, [min, max]);

  // const getTrackPosition = useCallback(() => {
  //   let val = value + offset;
  //   let n = 100 / (ticks.length - 1);
  //   let v = val * n;
  //   let v0 = v === 0 ? 0 : Math.round(v / n) * n;
  //   return v0;
  // }, [value, offset, ticks.length]);

//   const trackPosition = getTrackPosition();
  return (
      <div
        className={`stat-track stat-track-${
          label === "Momentum" ? "16" : max
        } `}
      >
        {!hideLabel && <span className="track-title modesto">{label}</span>}
        <div className={` slider-container ${hideThumb ? "hide-thumb" : ""}`}>
          <ul className="slider-ticks">
            {trackLabels !== undefined &&
            trackLabels.filter((tl) => tl !== "").length > 0 ? (
              <>
                {trackLabels.map((t) => (
                  <li key={t} className="slider-tick slider-tick-label">
                    <span>{t}</span>
                  </li>
                ))}
              </>
            ) : (
              <>
                {ticks.map((t, tickIndex) => (
                  <li key={tickIndex} className="slider-tick">
                    {t <= 0 ? `${t}` : `+${t}`}
                  </li>
                ))}
              </>
            )}
          </ul>
          <input
            type="range"
            min={min}
            max={max}
            step="1"
            value={value}
            onChange={(e) => onChange?.(e, label)}
            className="slider gh-slider-option4"
          />
        </div>
      </div>
  );
};
